import { SportSelectionUnitModel } from '@/modules/common/api/api';
import { FieldState, FormState } from 'formstate';
import { formStateValidators, INVALID_DATE_RANGE_ERROR } from 'shared';
import { SportSelectionType } from '@/modules/game-templates/utils/sport-selection-types';

export const getSportSelectionUnitFormState = (sportSelectionUnit?: SportSelectionUnitModel) => {
    const formState = new FormState({
        leagueId: new FieldState<number | undefined>(undefined).validators(
            formStateValidators.requiredNonNullableValue,
        ),
        matchIds: new FieldState(new Set<number>()).validators(value => {
            if (formState.$.type.value === SportSelectionType.Matches) {
                return value.size === 0 && 'Select a match';
            }

            return false;
        }),
        type: new FieldState<SportSelectionType>(SportSelectionType.Dates),
        startDate: new FieldState<Date | null>(null).validators(value => {
            if (formState.$.type.value === SportSelectionType.Dates) {
                return formStateValidators.dateValidator(value);
            }

            return false;
        }),
        endDate: new FieldState<Date | null>(null).validators(value => {
            if (formState.$.type.value === SportSelectionType.Dates) {
                return formStateValidators.dateValidator(value);
            }

            return false;
        }),
        registrationDeadline: new FieldState<Date | undefined>(undefined),
    })
        .compose()
        .validators($ => {
            if ($.type.value === SportSelectionType.Dates) {
                if ($.startDate.$!.getTime() >= $.endDate.$!.getTime()) {
                    return INVALID_DATE_RANGE_ERROR;
                }
            }

            // if ($.type.value === SportSelectionType.Matches) {
            //     return $.matchIds.value.size === 0 && 'Select a match';
            // }

            return false;
        });

    formState.$.type.onDidChange(({ newValue }) => {
        if (newValue === SportSelectionType.Matches) {
            if (formState.$.startDate.hasError) {
                formState.$.startDate.validate();
            }
            if (formState.$.endDate.hasError) {
                formState.$.endDate.validate();
            }
        }

        if (newValue === SportSelectionType.Dates) {
            if (formState.$.matchIds.hasError) {
                formState.$.matchIds.validate();
            }
        }
    });

    formState.disableAutoValidation();

    if (sportSelectionUnit) {
        formState.$.leagueId.onChange(sportSelectionUnit.leagueId);

        if (sportSelectionUnit.timeRange) {
            formState.$.startDate.onChange(sportSelectionUnit.timeRange.startDate);
            formState.$.endDate.onChange(sportSelectionUnit.timeRange.endDate);
        } else {
            formState.$.type.onChange(SportSelectionType.Matches);
            sportSelectionUnit.matchIds.forEach(matchId => {
                formState.$.matchIds.value.add(matchId);
            });
        }
    }

    return formState;
};
