import { makeAutoObservable, runInAction } from 'mobx';
import { IDataSource, ITableStateFilters } from './types';
import { rowsPerPageOptions } from './constants';

export class NewTableState<T> {
    filters: ITableStateFilters = {
        page: 0,
        searchTerm: '',
        rowsPerPage: rowsPerPageOptions[0],
    };
    rowsPerPageOptions = rowsPerPageOptions;
    dataSource;

    constructor(dataSource: IDataSource<T>) {
        this.dataSource = dataSource;
        makeAutoObservable(this);
    }

    init = (newFilters?: Partial<ITableStateFilters>) => {
        if (newFilters) {
            runInAction(() => {
                this.filters = {
                    ...this.filters,
                    ...newFilters,
                };
            });
        }

        return this.dataSource.init(this.filters);
    };

    fetchData = async (newFilters?: Partial<ITableStateFilters>) => {
        if (newFilters) {
            runInAction(() => {
                this.filters = {
                    ...this.filters,
                    ...newFilters,
                };
            });
        }

        try {
            await this.dataSource.fetchData(this.filters);
        } catch {
            //
        }
    };

    search = async (searchTerm: string) => {
        runInAction(() => {
            this.filters.page = 0;
            this.filters.searchTerm = searchTerm;
        });

        await this.fetchData();
    };

    changePage = async (page: typeof this.filters.page) => {
        runInAction(() => {
            this.filters.page = page;
        });

        await this.fetchData(this.filters);
    };

    changeRowsPerPage = async (rowsPerPage: typeof this.filters.rowsPerPage) => {
        runInAction(() => {
            this.filters.page = 0;
            this.filters.rowsPerPage = rowsPerPage;
        });

        await this.fetchData(this.filters);
    };
}
