import { observer } from 'mobx-react';
import { useDependencies } from 'ioc';
import { CommonTablePagination } from '@/modules/common/components/common-table-pagination/common-table-pagination';
import { SportLeaguesStore } from '@/modules/sports/stores/sport-leagues-store';

export const SportLeaguesPagination = observer(() => {
    const [{ tableState }] = useDependencies(SportLeaguesStore);

    return <CommonTablePagination tableState={tableState} />;
});
