import { useLayoutEffect, useRef } from 'react';
import { useDependencies } from 'ioc';
import { AuthStore } from '@/modules/app/stores/auth-store';
import { axiosInstance } from '@/modules/common/utils/axios-instance';
import { InternalAxiosRequestConfig } from 'axios';
import { toast } from '@/components/core/toaster';

export const useAuthInterceptors = () => {
    const [{ unAuthorize, handleUnauthorized }] = useDependencies(AuthStore);

    const retryRequestRef = useRef<Promise<any> | undefined>();

    useLayoutEffect(() => {
        axiosInstance.interceptors.request.use(
            config => {
                if (!config.headers) {
                    config.headers = {} as InternalAxiosRequestConfig['headers'];
                }

                config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;

                return config;
            },
            error => Promise.reject(error),
        );

        axiosInstance.interceptors.response.use(
            response => response,
            async error => {
                if (
                    error.response.status === 401 &&
                    !error.request.responseURL.includes('/refreshToken')
                ) {
                    try {
                        if (!retryRequestRef.current) {
                            retryRequestRef.current = handleUnauthorized();
                        }

                        await retryRequestRef.current;
                        retryRequestRef.current = undefined;

                        return axiosInstance(error.config);
                    } catch {
                        retryRequestRef.current = undefined;

                        const isMe = error.request.responseURL.endsWith('/me');
                        if (isMe) {
                            return Promise.reject(error);
                        }

                        unAuthorize();

                        toast.error('You are not authorized');
                    }
                } else {
                    toast.error(error.response.data.ErrorMessage ?? 'Something went wrong');
                }

                return Promise.reject(error);
            },
        );
    }, []);
};
