import { LoadStatus } from '../enums';
import { makeAutoObservable, runInAction } from 'mobx';
import { IDataSource, ITableStateData, ITableStateFilters } from './types';

export interface IInMemoryDataSource<T> extends IDataSource<T> {
    fullData: ITableStateData<T>;
    nameSelector: (item: T) => string;
}

export class InMemoryDataSource<T> implements IInMemoryDataSource<T> {
    loadStatus = LoadStatus.None;
    data: ITableStateData<T> = { items: [], total: 0 };
    fullData: ITableStateData<T> = { items: [], total: 0 };
    getData;
    nameSelector;

    constructor(
        getData: IInMemoryDataSource<T>['getData'],
        nameSelector: IInMemoryDataSource<T>['nameSelector'],
    ) {
        this.getData = getData;
        this.nameSelector = nameSelector;
        makeAutoObservable(this);
    }

    init = async (filters: ITableStateFilters) => {
        try {
            runInAction(() => {
                this.loadStatus = LoadStatus.Loading;
            });

            const fullData = await this.getData(filters);

            runInAction(() => {
                this.fullData = fullData;
            });

            await this.fetchData(filters);

            runInAction(() => {
                this.loadStatus = LoadStatus.Ok;
            });
        } catch {
            runInAction(() => {
                this.loadStatus = LoadStatus.Error;
            });
        }
    };

    // eslint-disable-next-line @typescript-eslint/require-await
    fetchData = async (filters: ITableStateFilters) => {
        try {
            const searchData = this.fullData.items.filter(item =>
                this.nameSelector(item).toLowerCase().includes(filters.searchTerm.toLowerCase()),
            );

            const startIndex = Math.round(filters.page * filters.rowsPerPage);
            const paginatedData = searchData.slice(startIndex, startIndex + filters.rowsPerPage);

            runInAction(() => {
                this.data = { items: paginatedData, total: searchData.length };
            });
        } catch {
            runInAction(() => {
                this.data = { items: [], total: 0 };
            });
        }
    };
}
