import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { provide, useDependencies } from 'ioc';
import { BetGraderStore } from '@/modules/bet-grader/stores/bet-grader-store';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { BetGraderFilters } from '@/modules/bet-grader/components/bet-grader-content/bet-grader-filters';
import { BetGraderPagination } from '@/modules/bet-grader/components/bet-grader-content/bet-grader-pagination';
import { BetGraderTable } from '@/modules/bet-grader/components/bet-grader-content/bet-grader-table';

interface BetGraderPageProps {
    isPending: boolean;
}

export const BetGraderPage: FC<BetGraderPageProps> = provide([BetGraderStore])(
    observer(({ isPending }) => {
        const [{ init }] = useDependencies(BetGraderStore);

        useEffect(() => {
            init(isPending);
        }, []);

        return (
            <Box
                sx={{
                    maxWidth: 'var(--Content-maxWidth)',
                    m: 'var(--Content-margin)',
                    p: 'var(--Content-padding)',
                    width: 'var(--Content-width)',
                }}
            >
                <Card>
                    <Typography variant="h5" p={3}>
                        {isPending ? 'Pending' : 'Historical'} Bets
                    </Typography>
                    <BetGraderFilters />
                    <Divider />
                    <Box sx={{ overflowX: 'auto' }}>
                        <BetGraderTable />
                    </Box>
                    <Divider />
                    <BetGraderPagination />
                </Card>
            </Box>
        );
    }),
);
