import { observer } from 'mobx-react';
import { useDependencies } from 'ioc';
import { GameLeaguesStore } from '@/modules/game-leagues/stores/game-leagues-store';
import { CommonTablePagination } from '@/modules/common/components/common-table-pagination/common-table-pagination';

export const GameLeaguesPagination = observer(() => {
    const [{ tableState }] = useDependencies(GameLeaguesStore);

    return <CommonTablePagination tableState={tableState} />;
});
