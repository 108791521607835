import { FC } from 'react';
import { observer } from 'mobx-react';
import { useDependencies } from 'ioc';
import { CommonTablePagination } from '@/modules/common/components/common-table-pagination/common-table-pagination';
import { SportSelectionUnitStore } from '@/modules/sport-selection-unit/stores/sport-selection-unit-store';

export const SportSelectionMatchesPagination: FC = observer(() => {
    const [{ matchesTableState }] = useDependencies(SportSelectionUnitStore);

    return <CommonTablePagination tableState={matchesTableState} />;
});
