import { injectable } from 'ioc';
import { makeAutoObservable, reaction } from 'mobx';
import { MatchModel } from '@/modules/common/api/api';
import { apiClient } from '@/modules/common/api/api-client';
import { noop, ITableStateDataGetter, NewTableState, InMemoryDataSource } from 'shared';
import { getSportSelectionUnitFormState } from '@/modules/game-templates/utils/get-sport-selection-unit-form-state';
import { SportSelectionType } from '@/modules/game-templates/utils/sport-selection-types';

@injectable()
export class SportSelectionUnitStore {
    formState: ReturnType<typeof getSportSelectionUnitFormState> = getSportSelectionUnitFormState();
    matchesTableState: NewTableState<MatchModel>;

    constructor() {
        this.matchesTableState = new NewTableState<MatchModel>(
            new InMemoryDataSource(this.getMatches, item => item.name),
        );
        makeAutoObservable(this);
    }

    init = async (formState: typeof this.formState) => {
        this.formState = formState;

        await this.matchesTableState.init();

        this.calculateRegistrationDeadline();

        reaction(() => this.formState.$.type.value, this.calculateRegistrationDeadline);
        reaction(() => this.formState.$.matchIds.value, this.calculateRegistrationDeadline);
        reaction(() => this.formState.$.startDate.value, this.calculateRegistrationDeadline);
    };

    selectMatches = (matchIds: number[], isAdding: boolean) => {
        matchIds.forEach(matchId => {
            this.formState.$.matchIds.value[isAdding ? 'add' : 'delete'](matchId);
        });

        this.refreshMatchIds();
    };

    // to update the reference and rerender
    refreshMatchIds = () => {
        this.formState.$.matchIds.onChange(new Set(this.formState.$.matchIds.value));
    };

    selectLeague = (leagueId: number) => {
        this.formState.$.leagueId.onChange(leagueId);
        this.formState.$.matchIds.value.clear();
        this.refreshMatchIds();
        this.matchesTableState.init({ page: 0, searchTerm: '' }).catch(noop);
    };

    getMatches: ITableStateDataGetter<MatchModel> = async (filters, abortSignal) => {
        try {
            if (!this.formState.$.leagueId.value) {
                throw new Error('League is not specified!');
            }

            const data = await apiClient.getMatchesAdmin(
                this.formState.$.leagueId.value,
                undefined,
                undefined,
                undefined,
                0,
                0,
                abortSignal,
            );

            return {
                items: data.items ?? [],
                total: data.totalAmount,
            };
        } catch {
            return {
                items: [],
                total: 0,
            };
        }
    };

    calculateRegistrationDeadline = () => {
        let result: Date | undefined;

        if (this.formState.$.leagueId.value) {
            if (this.formState.$.type.value === SportSelectionType.Dates) {
                result = this.formState.$.startDate.value ?? undefined;
            }

            if (this.formState.$.type.value === SportSelectionType.Matches) {
                const minDateTimestamp = Array.from(
                    this.formState.$.matchIds.value.values(),
                ).reduce((acc, val) => {
                    const matchId = val;

                    const match = (
                        this.matchesTableState.dataSource as InMemoryDataSource<MatchModel>
                    ).fullData.items.find(match => match.id === matchId);

                    if (match) {
                        return Math.min(acc, match.startDate.getTime());
                    }

                    return acc;
                }, Infinity);

                result = minDateTimestamp !== Infinity ? new Date(minDateTimestamp) : undefined;
            }
        }

        this.formState.$.registrationDeadline.onChange(result);
    };
}
