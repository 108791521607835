import { FC } from 'react';
import { observer } from 'mobx-react';
import { DebouncedSearch } from 'shared';
import { useDependencies } from 'ioc';
import { SportSelectionUnitStore } from '@/modules/sport-selection-unit/stores/sport-selection-unit-store';

export const SportSelectionMatchesFilters: FC = observer(() => {
    const [{ matchesTableState }] = useDependencies(SportSelectionUnitStore);

    return (
        <DebouncedSearch
            delay={0}
            fullWidth
            label={undefined}
            placeholder="Search matches"
            onSearch={matchesTableState.search}
        />
    );
});
