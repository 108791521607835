import { FC, Fragment, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useDependencies } from 'ioc';
import { SportSelectionUnitStore } from '@/modules/sport-selection-unit/stores/sport-selection-unit-store';
import { ColumnDef, DataTable } from '@/components/core/data-table';
import { MatchModel } from '@/modules/common/api/api';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { dayjs } from '@/lib/dayjs';
import { LoadStatus } from 'shared';
import { TableLoadingWrapper } from '@/modules/common/components/table-loading-wrapper/table-loading-wrapper';

export const SportSelectionMatchesTable: FC = observer(() => {
    const [{ matchesTableState, formState, selectMatches }] =
        useDependencies(SportSelectionUnitStore);

    const columns = useMemo(
        () =>
            [
                {
                    formatter: row => row.name,
                    name: 'Game',
                    width: '250px',
                },
                {
                    formatter: row => dayjs(row.startDate).format('M/D/YY h:mm A'),
                    name: 'Date',
                    width: '200px',
                },
            ] satisfies ColumnDef<MatchModel>[],
        [],
    );

    const data = matchesTableState.dataSource.data.items;

    const selectedIdsForPage = new Set(
        data.filter(({ id }) => formState.$.matchIds.value.has(id)).map(({ id }) => id),
    );

    return (
        <TableLoadingWrapper
            isLoading={matchesTableState.dataSource.loadStatus === LoadStatus.Loading}
        >
            <DataTable<MatchModel>
                columns={columns}
                rows={data}
                selectable
                selected={selectedIdsForPage}
                onSelectAll={() => {
                    selectMatches(
                        data.map(match => match.id),
                        true,
                    );
                }}
                onSelectOne={(_, row) => {
                    selectMatches([row.id], true);
                }}
                onDeselectAll={() => {
                    selectMatches(
                        data.map(match => match.id),
                        false,
                    );
                }}
                onDeselectOne={(_, row) => {
                    selectMatches([row.id], false);
                }}
            />
            {!data.length ? (
                <Box sx={{ p: 3 }}>
                    <Typography color="text.secondary" sx={{ textAlign: 'center' }} variant="body2">
                        No matches found
                    </Typography>
                </Box>
            ) : null}
        </TableLoadingWrapper>
    );
});
