import { injectable } from 'ioc';
import { makeAutoObservable, runInAction } from 'mobx';
import { LoadStatus, noop, TableState, ITableStateDataGetter } from 'shared';
import { SportLeagueModel, SportLeagueUpdateModel } from '@/modules/common/api/api';
import { apiClient } from '@/modules/common/api/api-client';

@injectable()
export class SportLeaguesStore {
    tableState: TableState<SportLeagueModel>;
    sportLeagueToEdit?: SportLeagueModel;

    constructor() {
        this.tableState = new TableState(this.getData);
        makeAutoObservable(this);
    }

    init = async () => {
        try {
            await this.tableState.fetchData();
        } catch {
            //
        }
    };

    startLeagueEditing = (sportLeague: SportLeagueModel) => {
        this.sportLeagueToEdit = sportLeague;
    };

    endLeagueEditing = () => {
        this.sportLeagueToEdit = undefined;
    };

    updateLeague = async (body: SportLeagueUpdateModel) => {
        if (!this.sportLeagueToEdit) {
            return;
        }

        try {
            await apiClient.putSportLeague(this.sportLeagueToEdit.id, body);

            this.tableState.fetchData().catch(noop);

            this.endLeagueEditing();
        } catch {
            //
        }
    };

    getData: ITableStateDataGetter<SportLeagueModel> = async (filters, abortSignal) => {
        const data = await apiClient.getSportLeagues(
            undefined,
            Math.round(filters.page * filters.rowsPerPage),
            filters.rowsPerPage,
            abortSignal,
        );

        return {
            items: data.items ?? [],
            total: data.totalAmount,
        };
    };
}
