import { SportSelectionMatchesFilters } from '@/modules/sport-selection-unit/components/sport-selection-matches-filters';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { SportSelectionMatchesTable } from '@/modules/sport-selection-unit/components/sport-selection-matches-table';
import { SportSelectionMatchesPagination } from '@/modules/sport-selection-unit/components/sport-selection-matches-pagination';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react';
import { useDependencies } from 'ioc';
import { SportSelectionUnitStore } from '@/modules/sport-selection-unit/stores/sport-selection-unit-store';

export const SportSelectionMatches = observer(() => {
    const [{ formState }] = useDependencies(SportSelectionUnitStore);

    return (
        <Box>
            <InputLabel required sx={{ mb: 1 }}>
                Matches
            </InputLabel>
            <Box mb={1}>
                <SportSelectionMatchesFilters />
            </Box>
            <Box sx={{ overflowX: 'auto' }}>
                <SportSelectionMatchesTable />
            </Box>
            <Divider />
            <SportSelectionMatchesPagination />
            <Typography
                variant="caption"
                color={formState.$.matchIds.hasError ? 'error' : undefined}
            >
                {formState.$.matchIds.value.size} matches selected
            </Typography>
        </Box>
    );
});
