import type { SVGProps } from 'react';

export const ChipIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
            fill="url(#paint0_linear_804_30487)"
        />
        <path
            d="M9.99884 19.6006C15.3011 19.6006 19.5995 15.3023 19.5995 10C19.5995 4.69771 15.3011 0.399353 9.99884 0.399353C4.69655 0.399353 0.398193 4.69771 0.398193 10C0.398193 15.3023 4.69655 19.6006 9.99884 19.6006Z"
            fill="url(#paint1_linear_804_30487)"
        />
        <mask
            id="mask0_804_30487"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="20"
            height="20"
        >
            <path
                d="M9.99713 19.6006C15.2994 19.6006 19.5978 15.3023 19.5978 10C19.5978 4.69771 15.2994 0.399353 9.99713 0.399353C4.69484 0.399353 0.396484 4.69771 0.396484 10C0.396484 15.3023 4.69484 19.6006 9.99713 19.6006Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask0_804_30487)">
            <path
                d="M5.87709 3.34949C6.52655 3.13031 7.14642 2.85466 7.73672 2.5212C8.23288 2.24151 8.47895 1.66198 8.34583 1.10799L7.73133 -1.42798L2.94043 0.714015L4.41817 2.86004C4.74223 3.32932 5.3379 3.53101 5.87844 3.34814L5.87709 3.34949Z"
                fill="url(#paint2_linear_804_30487)"
            />
            <path
                d="M2.38153 8.21301C2.68542 7.59851 2.9288 6.96519 3.11032 6.31305C3.26361 5.76444 3.0283 5.17953 2.54155 4.88371L0.313496 3.52563L-1.55957 8.42815L1.00329 8.90011C1.564 9.00365 2.12874 8.72397 2.38153 8.21301Z"
                fill="url(#paint3_linear_804_30487)"
            />
            <path
                d="M3.34777 14.1226C3.1286 13.4732 2.85295 12.8533 2.51948 12.263C2.2398 11.7668 1.66027 11.5208 1.10628 11.6539L-1.42969 12.2684L0.712304 17.0593L2.85833 15.5815C3.3276 15.2575 3.5293 14.6618 3.34643 14.1213L3.34777 14.1226Z"
                fill="url(#paint4_linear_804_30487)"
            />
            <path
                d="M8.21179 17.6173C7.59729 17.3134 6.96397 17.0701 6.31183 16.8885C5.76322 16.7352 5.17831 16.9706 4.88249 17.4573L3.52441 19.6854L8.42693 21.5584L8.8989 18.9956C9.00243 18.4349 8.72275 17.8701 8.21179 17.6173Z"
                fill="url(#paint5_linear_804_30487)"
            />
            <path
                d="M14.1217 16.6506C13.4722 16.8697 12.8524 17.1454 12.2621 17.4789C11.7659 17.7585 11.5199 18.3381 11.653 18.8921L12.2675 21.428L17.0584 19.286L15.5806 17.14C15.2566 16.6707 14.6609 16.469 14.1204 16.6519L14.1217 16.6506Z"
                fill="url(#paint6_linear_804_30487)"
            />
            <path
                d="M17.6161 11.7884C17.3122 12.4029 17.0688 13.0362 16.8873 13.6883C16.734 14.2369 16.9693 14.8219 17.4561 15.1177L19.6841 16.4757L21.5572 11.5732L18.9943 11.1013C18.4336 10.9977 17.8689 11.2774 17.6161 11.7884Z"
                fill="url(#paint7_linear_804_30487)"
            />
            <path
                d="M16.6495 5.87737C16.8687 6.52683 17.1443 7.1467 17.4778 7.737C17.7575 8.23316 18.337 8.47923 18.891 8.34611L21.427 7.73162L19.285 2.9407L17.139 4.41845C16.6697 4.74251 16.468 5.33818 16.6509 5.87872L16.6495 5.87737Z"
                fill="url(#paint8_linear_804_30487)"
            />
            <path
                d="M11.7876 2.3827C12.4021 2.68658 13.0354 2.92996 13.6875 3.11149C14.2361 3.26477 14.8211 3.02946 15.1169 2.54271L16.4749 0.314658L11.5724 -1.55841L11.1005 1.00445C10.9969 1.56516 11.2766 2.12991 11.7876 2.3827Z"
                fill="url(#paint9_linear_804_30487)"
            />
        </g>
        <path
            opacity="0.2"
            d="M10.0004 16.7514C13.7291 16.7514 16.7518 13.7287 16.7518 10C16.7518 6.27132 13.7291 3.24863 10.0004 3.24863C6.27172 3.24863 3.24902 6.27132 3.24902 10C3.24902 13.7287 6.27172 16.7514 10.0004 16.7514Z"
            fill="#3A3AD2"
        />
        <path
            d="M10.0971 16.7312C13.6958 16.7312 16.6132 13.8139 16.6132 10.2151C16.6132 6.61641 13.6958 3.69907 10.0971 3.69907C6.49839 3.69907 3.58105 6.61641 3.58105 10.2151C3.58105 13.8139 6.49839 16.7312 10.0971 16.7312Z"
            fill="url(#paint10_linear_804_30487)"
        />
        <path
            d="M13.4416 11.3847C13.4428 10.5246 12.9062 9.98614 11.7231 9.87692L11.7228 9.7692C12.7975 9.6633 13.121 9.12609 13.1226 8.37369C13.1235 7.40586 12.4791 6.86775 11.1887 6.86656L7.4256 6.86144L7.42908 13.1087L11.2999 13.1135C12.8052 13.1153 13.4512 12.4604 13.4518 11.3848L13.4416 11.3847ZM8.92975 8.1537L11.0803 8.15609C11.6148 8.15712 11.8302 8.37017 11.8319 8.79682C11.8324 8.7956 11.833 8.79437 11.8316 8.79082L11.8314 8.80105C11.8314 8.80105 11.8313 8.79805 11.8312 8.79505C11.8286 9.22128 11.6806 9.33763 11.0781 9.33705L8.92757 9.33465L8.92907 8.15192L8.92975 8.1537ZM11.2908 11.8229L8.92538 11.8198L8.92657 10.5294L11.2922 10.5222C11.8297 10.5231 12.044 10.7386 12.044 11.1689C12.044 11.5992 11.8278 11.825 11.2903 11.8241L11.2908 11.8229Z"
            fill="url(#paint11_linear_804_30487)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_804_30487"
                x1="6.02172"
                y1="29.0141"
                x2="13.2369"
                y2="-5.46848"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#58F081" />
                <stop offset="0.03" stopColor="#63F189" />
                <stop offset="0.13" stopColor="#8CF5A8" />
                <stop offset="0.25" stopColor="#B0F8C3" />
                <stop offset="0.37" stopColor="#CDFAD9" />
                <stop offset="0.49" stopColor="#E3FCEA" />
                <stop offset="0.63" stopColor="#F3FEF6" />
                <stop offset="0.79" stopColor="#FCFFFD" />
                <stop offset="1" stopColor="white" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_804_30487"
                x1="6.08213"
                y1="1.28558"
                x2="13.9143"
                y2="18.7138"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#58F081" />
                <stop offset="1" stopColor="#3A3AD2" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_804_30487"
                x1="12.5848"
                y1="23.7135"
                x2="4.95294"
                y2="-1.62099"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#58F081" />
                <stop offset="0.03" stopColor="#63F189" />
                <stop offset="0.13" stopColor="#8CF5A8" />
                <stop offset="0.25" stopColor="#B0F8C3" />
                <stop offset="0.37" stopColor="#CDFAD9" />
                <stop offset="0.49" stopColor="#E3FCEA" />
                <stop offset="0.63" stopColor="#F3FEF6" />
                <stop offset="0.79" stopColor="#FCFFFD" />
                <stop offset="1" stopColor="white" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_804_30487"
                x1="6.36491"
                y1="25.5874"
                x2="-1.26693"
                y2="0.252886"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#58F081" />
                <stop offset="0.03" stopColor="#63F189" />
                <stop offset="0.13" stopColor="#8CF5A8" />
                <stop offset="0.25" stopColor="#B0F8C3" />
                <stop offset="0.37" stopColor="#CDFAD9" />
                <stop offset="0.49" stopColor="#E3FCEA" />
                <stop offset="0.63" stopColor="#F3FEF6" />
                <stop offset="0.79" stopColor="#FCFFFD" />
                <stop offset="1" stopColor="white" />
            </linearGradient>
            <linearGradient
                id="paint4_linear_804_30487"
                x1="4.83178"
                y1="26.0502"
                x2="-2.80061"
                y2="0.714453"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#58F081" />
                <stop offset="0.03" stopColor="#63F189" />
                <stop offset="0.13" stopColor="#8CF5A8" />
                <stop offset="0.25" stopColor="#B0F8C3" />
                <stop offset="0.37" stopColor="#CDFAD9" />
                <stop offset="0.49" stopColor="#E3FCEA" />
                <stop offset="0.63" stopColor="#F3FEF6" />
                <stop offset="0.79" stopColor="#FCFFFD" />
                <stop offset="1" stopColor="white" />
            </linearGradient>
            <linearGradient
                id="paint5_linear_804_30487"
                x1="8.01627"
                y1="25.0906"
                x2="0.384449"
                y2="-0.24394"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#58F081" />
                <stop offset="0.03" stopColor="#63F189" />
                <stop offset="0.13" stopColor="#8CF5A8" />
                <stop offset="0.25" stopColor="#B0F8C3" />
                <stop offset="0.37" stopColor="#CDFAD9" />
                <stop offset="0.49" stopColor="#E3FCEA" />
                <stop offset="0.63" stopColor="#F3FEF6" />
                <stop offset="0.79" stopColor="#FCFFFD" />
                <stop offset="1" stopColor="white" />
            </linearGradient>
            <linearGradient
                id="paint6_linear_804_30487"
                x1="15.4182"
                y1="22.8609"
                x2="7.78633"
                y2="-2.47362"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#58F081" />
                <stop offset="0.03" stopColor="#63F189" />
                <stop offset="0.13" stopColor="#8CF5A8" />
                <stop offset="0.25" stopColor="#B0F8C3" />
                <stop offset="0.37" stopColor="#CDFAD9" />
                <stop offset="0.49" stopColor="#E3FCEA" />
                <stop offset="0.63" stopColor="#F3FEF6" />
                <stop offset="0.79" stopColor="#FCFFFD" />
                <stop offset="1" stopColor="white" />
            </linearGradient>
            <linearGradient
                id="paint7_linear_804_30487"
                x1="21.637"
                y1="20.9875"
                x2="14.0046"
                y2="-4.3482"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#58F081" />
                <stop offset="0.03" stopColor="#63F189" />
                <stop offset="0.13" stopColor="#8CF5A8" />
                <stop offset="0.25" stopColor="#B0F8C3" />
                <stop offset="0.37" stopColor="#CDFAD9" />
                <stop offset="0.49" stopColor="#E3FCEA" />
                <stop offset="0.63" stopColor="#F3FEF6" />
                <stop offset="0.79" stopColor="#FCFFFD" />
                <stop offset="1" stopColor="white" />
            </linearGradient>
            <linearGradient
                id="paint8_linear_804_30487"
                x1="23.1716"
                y1="20.5254"
                x2="15.5392"
                y2="-4.81036"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#58F081" />
                <stop offset="0.03" stopColor="#63F189" />
                <stop offset="0.13" stopColor="#8CF5A8" />
                <stop offset="0.25" stopColor="#B0F8C3" />
                <stop offset="0.37" stopColor="#CDFAD9" />
                <stop offset="0.49" stopColor="#E3FCEA" />
                <stop offset="0.63" stopColor="#F3FEF6" />
                <stop offset="0.79" stopColor="#FCFFFD" />
                <stop offset="1" stopColor="white" />
            </linearGradient>
            <linearGradient
                id="paint9_linear_804_30487"
                x1="19.986"
                y1="21.4844"
                x2="12.3542"
                y2="-3.85016"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#58F081" />
                <stop offset="0.03" stopColor="#63F189" />
                <stop offset="0.13" stopColor="#8CF5A8" />
                <stop offset="0.25" stopColor="#B0F8C3" />
                <stop offset="0.37" stopColor="#CDFAD9" />
                <stop offset="0.49" stopColor="#E3FCEA" />
                <stop offset="0.63" stopColor="#F3FEF6" />
                <stop offset="0.79" stopColor="#FCFFFD" />
                <stop offset="1" stopColor="white" />
            </linearGradient>
            <linearGradient
                id="paint10_linear_804_30487"
                x1="14.5831"
                y1="20.2492"
                x2="5.76654"
                y2="0.530332"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#58F081" />
                <stop offset="0.03" stopColor="#63F189" />
                <stop offset="0.13" stopColor="#8CF5A8" />
                <stop offset="0.25" stopColor="#B0F8C3" />
                <stop offset="0.37" stopColor="#CDFAD9" />
                <stop offset="0.49" stopColor="#E3FCEA" />
                <stop offset="0.63" stopColor="#F3FEF6" />
                <stop offset="0.79" stopColor="#FCFFFD" />
                <stop offset="1" stopColor="white" />
            </linearGradient>
            <linearGradient
                id="paint11_linear_804_30487"
                x1="12.8316"
                y1="0.290826"
                x2="8.32324"
                y2="17.2197"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#58F081" />
                <stop offset="1" stopColor="#3A3AD2" />
            </linearGradient>
        </defs>
    </svg>
);
