import { injectable } from 'ioc';
import { makeAutoObservable } from 'mobx';
import { FieldState, FormState } from 'formstate';
import { PlayerAdminModel, PlayerUpdateModel } from '@/modules/common/api/api';
import { formStateValidators } from 'shared';

@injectable()
export class UserEditStore {
    formState = new FormState({
        fullName: new FieldState('').validators(formStateValidators.required),
        username: new FieldState('').validators(formStateValidators.required),
        email: new FieldState('').validators(formStateValidators.required),
        phoneNumber: new FieldState('').validators(formStateValidators.required),
    });

    constructor() {
        this.formState.disableAutoValidation();
        makeAutoObservable(this);
    }

    init = async (user: PlayerAdminModel) => {
        const { fullName, username, email, phoneNumber } = user;

        this.formState.$.fullName.onChange(fullName);
        this.formState.$.username.onChange(username);
        this.formState.$.email.onChange(email);
        this.formState.$.phoneNumber.onChange(phoneNumber);
    };

    getValidatedData = async () => {
        await this.formState.enableAutoValidationAndValidate();

        if (this.formState.hasError) {
            throw new Error('Validation error');
        }

        const { fullName, username, email, phoneNumber } = this.formState.$;

        return new PlayerUpdateModel({
            fullName: fullName.$,
            username: username.$,
            email: email.$,
            phoneNumber: phoneNumber.$,
        });
    };
}
