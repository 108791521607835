import { observer } from 'mobx-react';
import { useDependencies } from 'ioc';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import { ArrowClockwise } from '@phosphor-icons/react';
import Divider from '@mui/material/Divider';
import { DebouncedSearch } from 'shared';
import Box from '@mui/material/Box';
import {
    RankedGamesStore,
    RankedGameStatusFilter,
} from '@/modules/ranked-games/stores/ranked-games-store';

export const RankedGamesFilters = observer(() => {
    const [{ statusFilter, applyStatusFilter, tableState }] = useDependencies(RankedGamesStore);

    return (
        <Box>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Tabs
                    onChange={(_, value: RankedGameStatusFilter) => {
                        applyStatusFilter(value);
                    }}
                    sx={{ px: 3 }}
                    value={statusFilter}
                    variant="scrollable"
                    scrollButtons={false}
                >
                    <Tab
                        label={RankedGameStatusFilter.Active}
                        tabIndex={0}
                        value={RankedGameStatusFilter.Active}
                    />
                    <Tab
                        label={RankedGameStatusFilter.Archived}
                        tabIndex={0}
                        value={RankedGameStatusFilter.Archived}
                    />
                </Tabs>
                <Button
                    onClick={() => tableState.fetchData()}
                    endIcon={<ArrowClockwise weight="bold" />}
                    sx={{ mr: 3 }}
                >
                    Refresh
                </Button>
            </Stack>
            <Divider />
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    alignItems: 'center',
                    px: 3,
                    py: 2,
                }}
            >
                <DebouncedSearch
                    label={undefined}
                    placeholder="Search by name"
                    onSearch={tableState.search}
                    sx={{ width: 250 }}
                />
            </Stack>
        </Box>
    );
});
