import { observer } from 'mobx-react';
import { useDependencies } from 'ioc';
import { CommonTablePagination } from '@/modules/common/components/common-table-pagination/common-table-pagination';
import { PlayersStore } from '@/modules/sports/stores/players-store';

export const PlayersPagination = observer(() => {
    const [{ tableState }] = useDependencies(PlayersStore);

    return <CommonTablePagination tableState={tableState} />;
});
