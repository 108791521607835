import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { GameLeagueAdminModel, GameLeagueUpdateAdminModel } from '@/modules/common/api/api';
import { provide, useDependencies } from 'ioc';
import { noop } from 'shared';
import { GameLeagueEditStore } from '@/modules/game-leagues/stores/game-league-edit-store';
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogTitle } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { X as XIcon } from '@phosphor-icons/react/dist/csr/X';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import { getFieldStateInputProps } from 'shared';
import { EditFormItem } from '@/modules/common/components/edit-form-item/edit-form-item';

interface GameLeaguesEditDialogProps {
    onClose: () => void;
    onApply: (updatedGameLeagueData: GameLeagueUpdateAdminModel) => void;
    initialValue: GameLeagueAdminModel;
}

export const GameLeagueEditDialog: FC<GameLeaguesEditDialogProps> = provide([GameLeagueEditStore])(
    observer(({ onApply, onClose, initialValue }) => {
        const [{ init, formState, getValidatedData }] = useDependencies(GameLeagueEditStore);

        useEffect(() => {
            init(initialValue);
        }, []);

        const { name } = formState.$;

        const handleApply = () => {
            getValidatedData()
                .then(gameLeague => {
                    if (gameLeague) {
                        onApply(gameLeague);
                    }
                })
                .catch(noop);
        };

        return (
            <Dialog
                open
                onClose={onClose}
                maxWidth="sm"
                sx={{
                    '& .MuiDialog-container': {
                        justifyContent: 'flex-end',
                    },
                    '& .MuiDialog-paper': { height: '100%', width: '100%' },
                }}
            >
                <DialogTitle>Edit League</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={theme => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <XIcon />
                </IconButton>
                <DialogContent>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Details
                    </Typography>
                    <Card component={Stack} divider={<Divider />}>
                        <EditFormItem name="League Name" required>
                            <OutlinedInput
                                fullWidth
                                placeholder="League Name"
                                {...getFieldStateInputProps(name)}
                            />
                        </EditFormItem>
                    </Card>
                </DialogContent>
                <DialogActions sx={{ px: 3, py: 2 }}>
                    <Button variant="contained" disabled={formState.hasError} onClick={handleApply}>
                        Save Changes
                    </Button>
                    <Button onClick={onClose} color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }),
);
