import { injectable } from 'ioc';
import { noop, ITableStateDataGetter, NewTableState, AsyncDataSource } from 'shared';
import { makeAutoObservable, runInAction } from 'mobx';
import {
    GameTemplatePublicModel,
    RankedGameTemplateListItemModel,
    RankedGameTemplateStatus,
    RankedGameTemplateUpsertModel,
    SportSelectionUnitModel,
} from '@/modules/common/api/api';
import { apiClient } from '@/modules/common/api/api-client';

export enum RankedGameStatusFilter {
    Active = 'Active',
    Archived = 'Archived',
}

@injectable()
export class RankedGamesStore {
    tableState: NewTableState<RankedGameTemplateListItemModel>;
    gameTemplateToEdit?: RankedGameTemplateListItemModel;
    isEditing = false;
    statusFilter = RankedGameStatusFilter.Active;
    sportSelectionMatchesToShow?: SportSelectionUnitModel[];

    constructor() {
        this.tableState = new NewTableState<RankedGameTemplateListItemModel>(
            new AsyncDataSource(this.getData),
        );
        makeAutoObservable(this);
    }

    init = async () => {
        try {
            await this.tableState.init();
        } catch {
            //
        }
    };

    applyStatusFilter = async (isActive: typeof this.statusFilter) => {
        runInAction(() => {
            this.statusFilter = isActive;
        });

        await this.tableState.fetchData({ page: 0 });
    };

    // deleteTemplate = async (id: RankedGameTemplateListItemModel['id']) => {
    //     try {
    //         await apiClient.gameTemplatesDELETE(id);
    //         this.tableState.fetchData().catch(noop);
    //     } catch {
    //         //
    //     }
    // };

    startTemplateCreating = () => {
        this.isEditing = true;
    };

    startTemplateEditing = (template: RankedGameTemplateListItemModel) => {
        this.isEditing = true;
        this.gameTemplateToEdit = template;
    };

    endTemplateEditing = () => {
        this.isEditing = false;
        this.gameTemplateToEdit = undefined;
    };

    saveTemplate = async (payload: RankedGameTemplateUpsertModel) => {
        try {
            if (this.gameTemplateToEdit) {
                await apiClient.updateRankedGameTemplate(this.gameTemplateToEdit.id, payload);
            } else {
                await apiClient.addRankedGameTemplate(payload);
            }

            this.tableState.fetchData().catch(noop);

            this.endTemplateEditing();
        } catch {
            // skip
        }
    };

    changeTemplateStatus = async (
        id: GameTemplatePublicModel['id'],
        newStatus: RankedGameTemplateStatus,
    ) => {
        try {
            await apiClient.updateRankedGameTemplateStatus(
                id,
                newStatus === RankedGameTemplateStatus.Active,
            );

            const editedDataItemIndex = this.tableState.dataSource.data.items.findIndex(
                item => item.id === id,
            );

            if (editedDataItemIndex !== -1) {
                runInAction(() => {
                    this.tableState.dataSource.data.items[editedDataItemIndex] =
                        new RankedGameTemplateListItemModel({
                            ...this.tableState.dataSource.data.items[editedDataItemIndex],
                            status: newStatus,
                        });
                });
            }
        } catch {
            // skip
        }
    };

    showMatches = async (id: RankedGameTemplateListItemModel['id']) => {
        try {
            const templateData = await apiClient.getRankedGameTemplate(id);

            runInAction(() => {
                this.sportSelectionMatchesToShow = templateData.leagues;
            });
        } catch {
            //
        }
    };

    hideMatches = () => {
        this.sportSelectionMatchesToShow = undefined;
    };

    getData: ITableStateDataGetter<RankedGameTemplateListItemModel> = async (
        filters,
        abortSignal,
    ) => {
        const data = await apiClient.getRankedGameTemplates(
            filters.searchTerm,
            this.statusFilter === RankedGameStatusFilter.Archived,
            Math.round(filters.page * filters.rowsPerPage),
            filters.rowsPerPage,
            abortSignal,
        );

        return {
            items: data.items ?? [],
            total: data.totalAmount,
        };
    };
}
