import { BetOddModel } from '@/modules/common/api/api';
import { FC, useEffect } from 'react';
import { provide, useDependencies } from 'ioc';
import { BetInfoStore } from '@/modules/bet-grader/stores/bet-info-store';
import { observer } from 'mobx-react';
import Popover, { PopoverProps } from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { PopoverInfoItem } from '@/modules/common/components/popover-info-item/popover-info-item';

interface BetInfoPopoverProps extends Omit<PopoverProps, 'open'> {
    betId: BetOddModel['id'];
}

export const BetInfoPopover: FC<BetInfoPopoverProps> = provide([BetInfoStore])(
    observer(({ betId, ...props }) => {
        const [{ init, data }] = useDependencies(BetInfoStore);

        useEffect(() => {
            init(betId);
        }, []);

        return (
            <Popover
                {...props}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                open
                slotProps={{ paper: { sx: { width: '300px' } } }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                <Box sx={{ p: 2 }}>
                    <Box mb={2}>
                        <Typography variant="h6">Info</Typography>
                    </Box>
                    {data ? (
                        <Stack spacing={2}>
                            <PopoverInfoItem field="Bet ID:" value={betId} copiable />
                            <PopoverInfoItem field="# of Bets:" value={data.betCount} />
                            <PopoverInfoItem field="# of Bettors:" value={data.playerCount} />
                        </Stack>
                    ) : (
                        <Box
                            height="136px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <CircularProgress />
                        </Box>
                    )}
                </Box>
            </Popover>
        );
    }),
);
