import { useMemo } from 'react';
import { observer } from 'mobx-react';
import { LoadStatus } from 'shared';
import { useDependencies } from 'ioc';
import { dayjs } from '@/lib/dayjs';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { PencilSimple } from '@phosphor-icons/react';
import { ColumnDef, DataTable } from '@/components/core/data-table';
import {
    RankedGameTemplateListItemModel,
    RankedGameTemplateStatus,
} from '@/modules/common/api/api';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import { TableLoadingWrapper } from '@/modules/common/components/table-loading-wrapper/table-loading-wrapper';
import {
    RankedGamesStore,
    RankedGameStatusFilter,
} from '@/modules/ranked-games/stores/ranked-games-store';
import { RankedGameTemplateEditDialog } from '@/modules/ranked-games/components/ranked-game-template-edit-dialog/ranked-game-template-edit-dialog';
import Button from '@mui/material/Button';
import { SportSelectionLeaguesDialog } from '@/modules/common/components/sport-selection-leagues/components/sport-selection-leagues-dialog';
import Chip from '@mui/material/Chip';

export const RankedGamesTable = observer(() => {
    const [
        {
            tableState,
            gameTemplateToEdit,
            changeTemplateStatus,
            endTemplateEditing,
            isEditing,
            saveTemplate,
            startTemplateEditing,
            statusFilter,
            sportSelectionMatchesToShow,
            showMatches,
            hideMatches,
        },
    ] = useDependencies(RankedGamesStore);

    const columns = useMemo(() => {
        return [
            {
                name: 'Game ID',
                width: '100px',
                formatter: row => `#${row.id}`,
            },
            {
                formatter: row => row.leagues.map(({ name }) => name).join(', '),
                name: 'Leagues',
                width: '200px',
            },
            {
                formatter: row => (
                    <Button variant="text" onClick={() => showMatches(row.id)}>
                        View Matches
                    </Button>
                ),
                name: 'Matches',
                width: '150px',
            },
            {
                formatter: row => {
                    return row.startDate ? dayjs(row.startDate).format('M/D/YY h:mm A') : '-';
                },
                name: 'Start Date',
                width: '150px',
            },
            {
                formatter: row => {
                    return row.endDate ? dayjs(row.endDate).format('M/D/YY h:mm A') : '-';
                },
                name: 'End Date',
                width: '150px',
            },
            {
                formatter: row => {
                    return row.publishDate ? dayjs(row.publishDate).format('M/D/YY h:mm A') : '-';
                },
                name: 'Publish Date',
                width: '150px',
            },
            {
                formatter: row => {
                    return row.publishDate.getTime() < Date.now() ? (
                        <Chip color="success" variant="outlined" label="Published" />
                    ) : (
                        <Chip color="default" variant="outlined" label="Scheduled" />
                    );
                },
                name: 'Status',
                width: '100px',
            },
            {
                formatter: row => {
                    return (
                        <Switch
                            onChange={(_, checked) => {
                                changeTemplateStatus(
                                    row.id,
                                    checked
                                        ? RankedGameTemplateStatus.Active
                                        : RankedGameTemplateStatus.Inactive,
                                );
                            }}
                            checked={row.status === RankedGameTemplateStatus.Active}
                        />
                    );
                },
                name: 'Live',
                width: '50px',
            },
            {
                formatter: row => {
                    return (
                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="end">
                            {statusFilter === RankedGameStatusFilter.Active && (
                                <IconButton
                                    color="primary"
                                    onClick={() => startTemplateEditing(row)}
                                >
                                    <PencilSimple weight="bold" />
                                </IconButton>
                            )}
                        </Stack>
                    );
                },
                name: 'Actions',
                width: '100px',
                align: 'right',
            },
        ] satisfies ColumnDef<RankedGameTemplateListItemModel>[];
    }, [changeTemplateStatus, showMatches, startTemplateEditing, statusFilter]);

    const data = tableState.dataSource.data.items;

    return (
        <TableLoadingWrapper isLoading={tableState.dataSource.loadStatus === LoadStatus.Loading}>
            <DataTable<RankedGameTemplateListItemModel> columns={columns} rows={data} />
            {!data.length ? (
                <Box sx={{ p: 3 }}>
                    <Typography color="text.secondary" sx={{ textAlign: 'center' }} variant="body2">
                        No templates found
                    </Typography>
                </Box>
            ) : null}
            {isEditing && (
                <RankedGameTemplateEditDialog
                    initialValue={gameTemplateToEdit}
                    onClose={endTemplateEditing}
                    onApply={saveTemplate}
                />
            )}
            {!!sportSelectionMatchesToShow && (
                <SportSelectionLeaguesDialog
                    units={sportSelectionMatchesToShow}
                    onClose={hideMatches}
                />
            )}
        </TableLoadingWrapper>
    );
});
