import { observer } from 'mobx-react';
import { useDependencies } from 'ioc';
import { BetGraderStore } from '@/modules/bet-grader/stores/bet-grader-store';
import { CommonTablePagination } from '@/modules/common/components/common-table-pagination/common-table-pagination';

export const BetGraderPagination = observer(() => {
    const [{ tableState }] = useDependencies(BetGraderStore);

    return <CommonTablePagination tableState={tableState} />;
});
