import { FC, useEffect } from 'react';
import { SportSelectionUnitModel } from '@/modules/common/api/api';
import Dialog from '@mui/material/Dialog';
import { DialogTitle } from '@mui/material';
import { X as XIcon } from '@phosphor-icons/react/X';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import { provide, useDependencies } from 'ioc';
import { SportSelectionLeaguesStore } from '@/modules/common/components/sport-selection-leagues/stores/sport-selection-leagues-store';
import { SportSelectionLeagueItem } from '@/modules/common/components/sport-selection-leagues/components/sport-selection-league-item';
import Box from '@mui/material/Box';

interface ISportSelectionMatchesProps {
    units: SportSelectionUnitModel[];
    onClose: () => void;
}

export const SportSelectionLeaguesDialog: FC<ISportSelectionMatchesProps> = provide([
    SportSelectionLeaguesStore,
])(({ units, onClose }) => {
    const [{ init }] = useDependencies(SportSelectionLeaguesStore);

    useEffect(() => {
        init();
    }, []);

    return (
        <Dialog
            open
            onClose={onClose}
            maxWidth="sm"
            sx={{
                '& .MuiDialog-paper': { height: '100%', width: '100%' },
            }}
        >
            <DialogTitle>All Matches</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={theme => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                })}
            >
                <XIcon />
            </IconButton>
            <DialogContent>
                {units.map(unit => (
                    <Box key={unit.leagueId} mb={2}>
                        <SportSelectionLeagueItem key={unit.leagueId} unit={unit} />
                    </Box>
                ))}
            </DialogContent>
        </Dialog>
    );
});
