import { FC, Fragment, useEffect } from 'react';
import { observer } from 'mobx-react';
import Dialog from '@mui/material/Dialog';
import { GameTemplatePublicModel, GameTemplateUpsertModel } from '@/modules/common/api/api';
import { DialogActions, DialogTitle } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { provide, useDependencies } from 'ioc';
import { getFieldStateInputProps, noop } from 'shared';
import { GameTemplateEditStore } from '@/modules/game-templates/stores/game-template-edit-store';
import IconButton from '@mui/material/IconButton';
import { Plus as PlusIcon, X as XIcon } from '@phosphor-icons/react';
import { ImageUploader } from '@/modules/common/components/image-uploader/image-uploader';
import Avatar from '@mui/material/Avatar';
import { EditFormItem } from '@/modules/common/components/edit-form-item/edit-form-item';
import Box from '@mui/material/Box';
import { SportSelectionUnit } from '@/modules/sport-selection-unit/components/sport-selection-unit';
import { dayjs } from '@/lib/dayjs';

interface GameTemplateEditDialogProps {
    onClose: () => void;
    onApply: (newGameTemplate: GameTemplateUpsertModel) => void;
    initialValue?: GameTemplatePublicModel;
}

export const GameTemplateEditDialog: FC<GameTemplateEditDialogProps> = provide([
    GameTemplateEditStore,
])(
    observer(({ onApply, onClose, initialValue }) => {
        const [
            {
                init,
                formState,
                isUploadingLogo,
                startLogoUploading,
                endLogoUploading,
                uploadLogoDirectly,
                uploadLogoByURL,
                removeLastUploadedLogo,
                getValidatedData,
                addLeague,
                registrationDeadline,
                removeLeague,
                setOpenedSportSelectionUnitId,
                openedSportSelectionUnitId,
                usedLeagueIds,
                availableLeagues,
            },
        ] = useDependencies(GameTemplateEditStore);

        useEffect(() => {
            init(initialValue);
        }, []);

        const { logo, name, leagues } = formState.$;

        const handleApply = () => {
            getValidatedData()
                .then(data => {
                    if (data) {
                        onApply(data);
                    }
                })
                .catch(noop);
        };

        const handleCancel = () => {
            removeLastUploadedLogo();
            onClose();
        };

        return (
            <Fragment>
                <Dialog
                    open
                    onClose={handleCancel}
                    maxWidth="sm"
                    sx={{
                        '& .MuiDialog-container': {
                            justifyContent: 'flex-end',
                        },
                        '& .MuiDialog-paper': { height: '100%', width: '100%' },
                    }}
                >
                    <DialogTitle>{initialValue ? 'Edit' : 'New'} Game Template</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={theme => ({
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme.palette.grey[500],
                        })}
                    >
                        <XIcon />
                    </IconButton>
                    <DialogContent>
                        <EditFormItem p={1} name="Logo">
                            <Stack direction="row" spacing={2}>
                                {!!logo.value.url && (
                                    <Avatar
                                        variant="rounded"
                                        src={logo.value.url}
                                        sx={{
                                            '& img': {
                                                objectFit: 'unset',
                                            },
                                        }}
                                    />
                                )}
                                <Button onClick={startLogoUploading}>Upload Logo</Button>
                            </Stack>
                        </EditFormItem>
                        <Box sx={{ mb: 4 }}>
                            <EditFormItem p={1} name="Name" required>
                                <OutlinedInput
                                    fullWidth
                                    placeholder="Template Name"
                                    {...getFieldStateInputProps(name)}
                                />
                            </EditFormItem>
                        </Box>
                        {Array.from(leagues.$.entries()).map(([id, formState], index) => {
                            return (
                                <Box key={id}>
                                    <SportSelectionUnit
                                        usedLeagueIds={usedLeagueIds}
                                        availableLeagues={availableLeagues}
                                        setOpenedSportSelectionUnitId={
                                            setOpenedSportSelectionUnitId
                                        }
                                        leaguesFormState={leagues}
                                        openedSportSelectionUnitId={openedSportSelectionUnitId}
                                        onRemoveLeague={removeLeague}
                                        id={id}
                                        formState={formState}
                                        index={index}
                                    />
                                    <Divider />
                                </Box>
                            );
                        })}
                        <Box display="flex" justifyContent="end">
                            <Button
                                onClick={addLeague}
                                sx={{ mt: 2 }}
                                variant="outlined"
                                endIcon={<PlusIcon />}
                            >
                                Add League
                            </Button>
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ px: 3, py: 2 }}>
                        <Stack
                            direction="row"
                            width="100%"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Typography variant="caption">
                                {registrationDeadline
                                    ? `Users can register until ${dayjs(registrationDeadline).format('M/D/YY h:mm A')}`
                                    : ''}
                            </Typography>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Button
                                    variant="contained"
                                    disabled={formState.hasError}
                                    onClick={handleApply}
                                >
                                    {initialValue ? 'Save Changes' : 'Create'}
                                </Button>
                                <Button onClick={handleCancel} color="secondary">
                                    Cancel
                                </Button>
                            </Stack>
                        </Stack>
                    </DialogActions>
                </Dialog>
                {isUploadingLogo && (
                    <ImageUploader
                        onDirectUpload={file =>
                            uploadLogoDirectly({
                                fileName: file.name,
                                data: file,
                            })
                        }
                        onURLUpload={uploadLogoByURL}
                        onClose={endLogoUploading}
                    />
                )}
            </Fragment>
        );
    }),
);
