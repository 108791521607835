import { LoadStatus } from '../enums';
import { makeAutoObservable, runInAction, when } from 'mobx';
import { IDataSource, ITableStateData, ITableStateDataGetter, ITableStateFilters } from './types';
import axios from 'axios';

export class AsyncDataSource<T> implements IDataSource<T> {
    loadStatus = LoadStatus.None;
    getData;
    data: ITableStateData<T> = { items: [], total: 0 };
    abortController = new AbortController();

    constructor(getData: ITableStateDataGetter<T>) {
        this.getData = getData;
        makeAutoObservable(this);
    }

    init = async (filters: ITableStateFilters) => {
        if (this.loadStatus !== LoadStatus.None) {
            return;
        }

        await this.fetchData(filters);
    };

    fetchData = async (filters: ITableStateFilters) => {
        this.abortController.abort();
        await when(() => this.loadStatus !== LoadStatus.Loading);
        this.abortController = new AbortController();

        try {
            runInAction(() => {
                this.loadStatus = LoadStatus.Loading;
            });

            const data = await this.getData(filters, this.abortController.signal);

            runInAction(() => {
                this.data = data;
                this.loadStatus = LoadStatus.Ok;
            });
        } catch (error) {
            if (axios.isCancel(error)) {
                runInAction(() => {
                    this.loadStatus = LoadStatus.Ok;
                });

                return;
            }

            runInAction(() => {
                this.loadStatus = LoadStatus.Error;
                this.data = { items: [], total: 0 };
            });
        }
    };
}
