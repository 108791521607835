import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import Dialog from '@mui/material/Dialog';
import {
    RankedGameTemplateListItemModel,
    RankedGameTemplateUpsertModel,
} from '@/modules/common/api/api';
import { DialogActions, DialogTitle } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { provide, useDependencies } from 'ioc';
import { getFieldStateInputProps, noop } from 'shared';
import IconButton from '@mui/material/IconButton';
import { Plus as PlusIcon, X as XIcon } from '@phosphor-icons/react';
import { EditFormItem } from '@/modules/common/components/edit-form-item/edit-form-item';
import Box from '@mui/material/Box';
import { SportSelectionUnit } from '@/modules/sport-selection-unit/components/sport-selection-unit';
import { dayjs } from '@/lib/dayjs';
import { RankedGameTemplateEditStore } from '@/modules/ranked-games/stores/ranked-game-template-edit-store';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

interface RankedGameTemplateEditDialogProps {
    onClose: () => void;
    onApply: (newGameTemplate: RankedGameTemplateUpsertModel) => void;
    initialValue?: RankedGameTemplateListItemModel;
}

export const RankedGameTemplateEditDialog: FC<RankedGameTemplateEditDialogProps> = provide([
    RankedGameTemplateEditStore,
])(
    observer(({ onApply, onClose, initialValue }) => {
        const [
            {
                init,
                formState,
                getValidatedData,
                addLeague,
                registrationDeadline,
                removeLeague,
                setOpenedSportSelectionUnitId,
                openedSportSelectionUnitId,
                availableLeagues,
                usedLeagueIds,
            },
        ] = useDependencies(RankedGameTemplateEditStore);

        useEffect(() => {
            init(initialValue);
        }, []);

        const { publishDate, name, leagues } = formState.$;

        const handleApply = () => {
            getValidatedData()
                .then(data => {
                    if (data) {
                        onApply(data);
                    }
                })
                .catch(noop);
        };

        const handleCancel = () => {
            onClose();
        };

        return (
            <Dialog
                open
                onClose={handleCancel}
                maxWidth="sm"
                sx={{
                    '& .MuiDialog-container': {
                        justifyContent: 'flex-end',
                    },
                    '& .MuiDialog-paper': { height: '100%', width: '100%' },
                }}
            >
                <DialogTitle>{initialValue ? 'Edit' : 'New'} Ranked Game</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={theme => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <XIcon />
                </IconButton>
                <DialogContent>
                    <EditFormItem p={1} name="Game Name">
                        <OutlinedInput
                            fullWidth
                            placeholder="New Name"
                            {...getFieldStateInputProps(name)}
                        />
                    </EditFormItem>
                    <Box sx={{ mb: 4 }}>
                        <EditFormItem p={1} name="Publish Date" required>
                            <DateTimePicker
                                onChange={value => {
                                    publishDate.onChange(value ? value.toDate() : null);
                                }}
                                sx={{ width: '100%' }}
                                format="MMM D, YYYY hh:mm A"
                                value={publishDate.value ? dayjs(publishDate.value) : null}
                                slotProps={{
                                    textField: {
                                        onBlur: publishDate.enableAutoValidationAndValidate,
                                        error: publishDate.hasError,
                                        helperText: publishDate.error,
                                    },
                                }}
                            />
                        </EditFormItem>
                    </Box>
                    {Array.from(leagues.$.entries()).map(([id, formState], index) => {
                        return (
                            <Box key={id}>
                                <SportSelectionUnit
                                    usedLeagueIds={usedLeagueIds}
                                    availableLeagues={availableLeagues}
                                    setOpenedSportSelectionUnitId={setOpenedSportSelectionUnitId}
                                    leaguesFormState={leagues}
                                    openedSportSelectionUnitId={openedSportSelectionUnitId}
                                    onRemoveLeague={removeLeague}
                                    id={id}
                                    formState={formState}
                                    index={index}
                                />
                                <Divider />
                            </Box>
                        );
                    })}
                    <Box display="flex" justifyContent="end">
                        <Button
                            onClick={addLeague}
                            sx={{ mt: 2 }}
                            variant="outlined"
                            endIcon={<PlusIcon />}
                        >
                            Add League
                        </Button>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ px: 3, py: 2 }}>
                    <Stack
                        direction="row"
                        width="100%"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography variant="caption">
                            {registrationDeadline
                                ? `Users can register until ${dayjs(registrationDeadline).format('M/D/YY h:mm A')}`
                                : ''}
                        </Typography>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Button
                                variant="contained"
                                disabled={formState.hasError}
                                onClick={handleApply}
                            >
                                {initialValue ? 'Save Changes' : 'Create'}
                            </Button>
                            <Button onClick={handleCancel} color="secondary">
                                Cancel
                            </Button>
                        </Stack>
                    </Stack>
                </DialogActions>
            </Dialog>
        );
    }),
);
