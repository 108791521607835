import { injectable } from 'ioc';
import { makeAutoObservable, runInAction } from 'mobx';
import { FieldState, FormState } from 'formstate';
import {
    IdNameModel,
    RankedGameTemplateListItemModel,
    RankedGameTemplateUpsertModel,
    SportSelectionUnitModel,
    TimeRange,
} from '@/modules/common/api/api';
import { apiClient } from '@/modules/common/api/api-client';
import { formStateValidators, noop } from 'shared';
import { getSportSelectionUnitFormState } from '@/modules/game-templates/utils/get-sport-selection-unit-form-state';
import { SportSelectionType } from '@/modules/game-templates/utils/sport-selection-types';

@injectable()
export class RankedGameTemplateEditStore {
    formState = new FormState({
        name: new FieldState('').validators(formStateValidators.required),
        publishDate: new FieldState<Date | null>(null).validators(
            formStateValidators.dateValidator,
        ),
        leagues: new FormState(
            new Map<number, ReturnType<typeof getSportSelectionUnitFormState>>(),
        ),
    });

    availableLeagues: IdNameModel[] = [];
    openedSportSelectionUnitId?: number;

    constructor() {
        this.formState.disableAutoValidation();
        makeAutoObservable(this);
    }

    init = async (gameTemplate?: RankedGameTemplateListItemModel) => {
        try {
            this.fetchLeagues().catch(noop);

            if (gameTemplate) {
                const template = await apiClient.getRankedGameTemplate(gameTemplate.id);

                const { name, publishDate } = gameTemplate;

                this.formState.$.name.onChange(name);
                this.formState.$.publishDate.onChange(publishDate);

                if (template.leagues.length) {
                    template.leagues.forEach(sportSelectionUnit => {
                        this.formState.$.leagues.$.set(
                            Math.random(),
                            getSportSelectionUnitFormState(sportSelectionUnit),
                        );
                    });
                }
            }

            if (!this.formState.$.leagues.$.size) {
                const key = Math.random();

                this.formState.$.leagues.$.set(key, getSportSelectionUnitFormState());
                this.setOpenedSportSelectionUnitId(key);
            }
        } catch {
            //
        }
    };

    setOpenedSportSelectionUnitId = (id?: number) => {
        this.openedSportSelectionUnitId = id;
    };

    getValidatedData = async () => {
        await this.formState.enableAutoValidationAndValidate();

        if (this.formState.hasError || !this.registrationDeadline) {
            throw new Error('Validation error');
        }

        const formStateValues = this.formState.$;

        return new RankedGameTemplateUpsertModel({
            name: formStateValues.name.$,
            publishDate: formStateValues.publishDate.$!,
            registrationEndDate: this.registrationDeadline,
            leagues: Array.from(formStateValues.leagues.$.values()).map(
                formState =>
                    new SportSelectionUnitModel({
                        leagueId: formState.$.leagueId.value!,
                        timeRange:
                            formState.$.type.value === SportSelectionType.Dates
                                ? new TimeRange({
                                      startDate: formState.$.startDate.value!,
                                      endDate: formState.$.endDate.value!,
                                  })
                                : undefined,
                        matchIds:
                            formState.$.type.value === SportSelectionType.Matches
                                ? Array.from(formState.$.matchIds.value)
                                : [],
                    }),
            ),
        });
    };

    fetchLeagues = async () => {
        try {
            const { items: availableLeagues } = await apiClient.getSportLeagues(
                undefined,
                undefined,
                undefined,
            );

            runInAction(() => {
                this.availableLeagues = availableLeagues;
            });
        } catch {
            //
        }
    };

    addLeague = () => {
        const key = Math.random();

        this.formState.$.leagues.$.set(key, getSportSelectionUnitFormState());
        this.setOpenedSportSelectionUnitId(key);
    };

    removeLeague = (id: number) => {
        this.formState.$.leagues.$.delete(id);
    };

    get usedLeagueIds() {
        return Array.from(this.formState.$.leagues.$.values()).map(
            formState => formState.$.leagueId.value,
        );
    }

    get registrationDeadline() {
        const sportSelectionUnits = Array.from(this.formState.$.leagues.$.values());

        if (!sportSelectionUnits.every(item => !!item.$.registrationDeadline.value)) {
            return undefined;
        }

        const deadlineTimestamp = sportSelectionUnits.reduce((acc, val) => {
            if (!val.$.registrationDeadline.value) {
                return acc;
            }

            return Math.min(acc, val.$.registrationDeadline.value.getTime());
        }, Infinity);

        return deadlineTimestamp !== Infinity ? new Date(deadlineTimestamp) : undefined;
    }
}
