import { injectable } from 'ioc';
import { makeAutoObservable, runInAction } from 'mobx';
import { apiClient } from '@/modules/common/api/api-client';
import { LoadStatus } from 'shared';

@injectable()
export class DashboardStore {
    onlinePlayersCount = 0;
    loadStatus = LoadStatus.None;

    constructor() {
        makeAutoObservable(this);
    }

    init = async () => {
        runInAction(() => {
            this.loadStatus = LoadStatus.Loading;
        });

        try {
            const onlinePlayersCount = await apiClient.getOnlinePlayerCount();

            runInAction(() => {
                this.onlinePlayersCount = onlinePlayersCount;
                this.loadStatus = LoadStatus.Ok;
            });
        } catch {
            runInAction(() => {
                this.loadStatus = LoadStatus.Error;
            });
        }
    };
}
