import { FC, useEffect } from 'react';
import { provide, useDependencies } from 'ioc';
import { getFieldStateInputProps, noop } from 'shared';
import { observer } from 'mobx-react';
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogTitle } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { X as XIcon } from '@phosphor-icons/react/dist/csr/X';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { EditFormItem } from '@/modules/common/components/edit-form-item/edit-form-item';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { PlayerAdminModel, PlayerUpdateModel } from '@/modules/common/api/api';
import { UserEditStore } from '@/modules/users/stores/user-edit-store';

interface UserEditDialogProps {
    onClose: () => void;
    onApply: (editedData: PlayerUpdateModel) => void;
    initialValue: PlayerAdminModel;
}

export const UserEditDialog: FC<UserEditDialogProps> = provide([UserEditStore])(
    observer(({ onApply, onClose, initialValue }) => {
        const [{ init, formState, getValidatedData }] = useDependencies(UserEditStore);

        useEffect(() => {
            init(initialValue);
        }, []);

        const { fullName, username, email, phoneNumber } = formState.$;

        const handleApply = () => {
            getValidatedData()
                .then(data => {
                    if (data) {
                        onApply(data);
                    }
                })
                .catch(noop);
        };

        return (
            <Dialog
                open
                onClose={onClose}
                maxWidth="sm"
                sx={{
                    '& .MuiDialog-container': {
                        justifyContent: 'flex-end',
                    },
                    '& .MuiDialog-paper': { height: '100%', width: '100%' },
                }}
            >
                <DialogTitle>Edit User Profile</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={theme => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <XIcon />
                </IconButton>
                <DialogContent>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Details
                    </Typography>
                    <Card component={Stack} divider={<Divider />}>
                        <EditFormItem name="Full Name" required>
                            <OutlinedInput
                                fullWidth
                                placeholder="Full Name"
                                {...getFieldStateInputProps(fullName)}
                            />
                        </EditFormItem>
                        <EditFormItem name="Username" required>
                            <OutlinedInput
                                fullWidth
                                placeholder="Username"
                                {...getFieldStateInputProps(username)}
                            />
                        </EditFormItem>
                        <EditFormItem name="Email" required>
                            <OutlinedInput
                                fullWidth
                                placeholder="Email"
                                {...getFieldStateInputProps(email)}
                            />
                        </EditFormItem>
                        <EditFormItem name="Phone Number" required>
                            <OutlinedInput
                                fullWidth
                                placeholder="Phone Number"
                                {...getFieldStateInputProps(phoneNumber)}
                            />
                        </EditFormItem>
                    </Card>
                </DialogContent>
                <DialogActions sx={{ px: 3, py: 2 }}>
                    <Button variant="contained" disabled={formState.hasError} onClick={handleApply}>
                        {initialValue ? 'Save Changes' : 'Create'}
                    </Button>
                    <Button onClick={onClose} color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }),
);
