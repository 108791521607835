import { paths } from '@/paths';
import type { NavItemConfig } from '@/types/nav';
import { MainNav } from '@/components/dashboard/layout/horizontal/main-nav';

const navItems = [
    {
        key: 'navigation',
        items: [
            {
                key: 'dashboard',
                title: 'Dashboard',
                href: paths.dashboard.overview,
                icon: 'house',
            },
            {
                key: 'bet-grader',
                title: 'Bet Grader',
                href: paths.betGrader.index,
                icon: 'list-checks',
                matcher: {
                    type: 'startsWith',
                    href: paths.betGrader.index,
                },
                items: [
                    {
                        key: 'pending',
                        title: 'Pending',
                        href: paths.betGrader.pending,
                    },
                    {
                        key: 'historical',
                        title: 'Historical',
                        href: paths.betGrader.historical,
                    },
                ],
            },
            {
                key: 'sports',
                title: 'Sports',
                href: paths.sports.index,
                icon: 'strategy',
                matcher: {
                    type: 'startsWith',
                    href: paths.sports.index,
                },
                items: [
                    {
                        key: 'teams',
                        title: 'Teams',
                        href: paths.sports.teams,
                    },
                    {
                        key: 'players',
                        title: 'Players',
                        href: paths.sports.players,
                    },
                    {
                        key: 'leagues',
                        title: 'Leagues',
                        href: paths.sports.leagues,
                    },
                ],
            },
            {
                key: 'game-templates',
                title: 'Game Templates',
                href: paths.gameTemplates,
                icon: 'game-controller',
            },
            {
                key: 'leagues',
                title: 'Leagues',
                href: paths.leagues,
                icon: 'trophy',
            },
            {
                key: 'users',
                title: 'Users',
                href: paths.users,
                icon: 'users',
            },
            {
                key: 'ranked-games',
                title: 'Ranked Games',
                href: paths.rankedGames,
                icon: 'crown',
            },
        ],
    },
] satisfies NavItemConfig[];

export const Navigation = () => {
    return <MainNav color="discrete" items={navItems} />;
};
