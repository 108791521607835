import { NewTableState, TableState } from 'shared';
import { FC } from 'react';
import TablePagination from '@mui/material/TablePagination';
import { observer } from 'mobx-react';

interface CommonTablePaginationProps {
    tableState: NewTableState<unknown> | TableState<unknown>;
}

export const CommonTablePagination: FC<CommonTablePaginationProps> = observer(({ tableState }) => {
    const data = tableState instanceof NewTableState ? tableState.dataSource.data : tableState.data;

    return (
        <TablePagination
            component="div"
            count={data.total}
            onPageChange={(_, newPage) => {
                tableState.changePage(newPage);
            }}
            onRowsPerPageChange={event => {
                tableState.changeRowsPerPage(
                    +event.target.value as typeof tableState.filters.rowsPerPage,
                );
            }}
            page={tableState.filters.page}
            rowsPerPage={tableState.filters.rowsPerPage}
            rowsPerPageOptions={tableState.rowsPerPageOptions}
        />
    );
});
