import { observer } from 'mobx-react';
import { useDependencies } from 'ioc';
import { CommonTablePagination } from '@/modules/common/components/common-table-pagination/common-table-pagination';
import { RankedGamesStore } from '@/modules/ranked-games/stores/ranked-games-store';

export const RankedGamesPagination = observer(() => {
    const [{ tableState }] = useDependencies(RankedGamesStore);

    return <CommonTablePagination tableState={tableState} />;
});
