import { injectable } from 'ioc';
import { makeAutoObservable, runInAction } from 'mobx';
import { noop, TableState, ITableStateDataGetter } from 'shared';
import {
    GameLeagueAdminModel,
    GameLeagueStateEnum,
    GameLeagueStatusEnum,
    GameLeagueUpdateAdminModel,
} from '@/modules/common/api/api';
import { apiClient } from '@/modules/common/api/api-client';

@injectable()
export class GameLeaguesStore {
    tableState: TableState<GameLeagueAdminModel>;
    statusFilter?: GameLeagueStatusEnum;
    stateFilter?: GameLeagueStateEnum;

    gameLeagueToEdit?: GameLeagueAdminModel;

    constructor() {
        this.tableState = new TableState(this.getData);
        makeAutoObservable(this);
    }

    init = () => {
        this.tableState.fetchData().catch(noop);
    };

    applyStateFilter = async (stateFilter: typeof this.stateFilter) => {
        runInAction(() => {
            this.stateFilter = stateFilter;
        });

        await this.tableState.fetchData({ page: 0 });
    };

    applyStatusFilter = async (statusFilter: typeof this.statusFilter) => {
        runInAction(() => {
            this.statusFilter = statusFilter;
        });

        await this.tableState.fetchData({ page: 0 });
    };

    startEditing = (gameLeague: typeof this.gameLeagueToEdit) => {
        this.gameLeagueToEdit = gameLeague;
    };

    endEditing = () => {
        this.gameLeagueToEdit = undefined;
    };

    editLeague = async (body: GameLeagueUpdateAdminModel) => {
        if (!this.gameLeagueToEdit) {
            return;
        }

        try {
            await apiClient.updateGameLeague(this.gameLeagueToEdit.id, body);

            this.tableState.fetchData().catch(noop);

            this.endEditing();
        } catch {
            //
        }
    };

    deleteGameLeague = async (id: GameLeagueAdminModel['id']) => {
        try {
            await apiClient.deleteGameLeague(id);
            this.tableState.fetchData().catch(noop);
        } catch {
            //
        }
    };

    getData: ITableStateDataGetter<GameLeagueAdminModel> = async (filters, abortSignal) => {
        const data = await apiClient.getGameLeagues(
            filters.searchTerm,
            this.statusFilter,
            this.stateFilter,
            Math.round(filters.page * filters.rowsPerPage),
            filters.rowsPerPage,
            abortSignal,
        );

        return {
            items: data.items ?? [],
            total: data.totalAmount,
        };
    };
}
