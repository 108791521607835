import { Stack, Tooltip, Typography, TypographyProps } from '@mui/material';
import { ChipIcon } from '../icons/chip-icon';
import { formatBigNumber, isBigNumber } from '../../utils';

export const Balance = ({
    amount = 0,
    typographyProps = {},
}: {
    amount?: number;
    typographyProps?: TypographyProps;
}) => {
    return (
        <Stack direction="row" alignItems="center" spacing={0.5}>
            <ChipIcon />
            <Tooltip
                arrow
                title={isBigNumber(amount) ? amount.toLocaleString() : ''}
                placement="top"
            >
                <Typography {...typographyProps}>{formatBigNumber(amount)}</Typography>
            </Tooltip>
        </Stack>
    );
};
