import { observer } from 'mobx-react';
import { useDependencies } from 'ioc';
import { LoadStatus } from 'shared';
import { useMemo } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ColumnDef, DataTable } from '@/components/core/data-table';
import { PlayerAdminModel, UserStatusEnum } from '@/modules/common/api/api';
import IconButton from '@mui/material/IconButton';
import { PencilSimple, Trash } from '@phosphor-icons/react';
import { UsersStore } from '@/modules/users/stores/users-store';
import { useConfirm } from 'shared';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import { BlockUserDialog } from '@/modules/users/components/block-user-dialog/block-user-dialog';
import { UserNotesDialog } from '@/modules/users/components/user-notes-dialog/user-notes-dialog';
import { UserEditDialog } from '@/modules/users/components/user-edit-dialog/user-edit-dialog';
import { TableLoadingWrapper } from '@/modules/common/components/table-loading-wrapper/table-loading-wrapper';

export const UsersTable = observer(() => {
    const [
        {
            tableState,
            userToEdit,
            userToViewNotes,
            startUpdating,
            endUpdating,
            deleteUser,
            update,
            startViewingNotes,
            blockUser,
            unblockUser,
            userToBlock,
            startBlocking,
            endBlocking,
        },
    ] = useDependencies(UsersStore);

    const [DeleteConfirmation, handleDeleteWithConfirmation] = useConfirm(deleteUser);

    const [UnblockConfirmationConfirmation, handleUnblockWithConfirmation] =
        useConfirm(unblockUser);

    const columns = useMemo(() => {
        return [
            {
                name: 'Full Name',
                width: '250px',
                formatter: row => (
                    <Stack direction="row" spacing={1} pl={1} sx={{ alignItems: 'center' }}>
                        <Badge color="success" variant="dot" />
                        <Avatar src={row.avatarUrl} />
                        <Typography variant="inherit">{row.fullName}</Typography>
                    </Stack>
                ),
            },
            {
                name: 'Username',
                width: '120px',
                formatter: row => `@${row.username}`,
            },
            {
                name: 'Email',
                width: '150px',
                formatter: row => row.email,
            },
            {
                name: 'Phone Number',
                width: '150px',
                formatter: row => row.phoneNumber,
            },
            {
                name: 'Login Attempts',
                width: '140px',
                formatter: row => row.loginAttempts,
            },
            {
                name: 'Notes',
                width: '130px',
                formatter: row => (
                    <Button onClick={() => startViewingNotes(row)}>View Notes</Button>
                ),
            },
            {
                formatter: row => {
                    const isActive = row.status === UserStatusEnum.Active;
                    return (
                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="end">
                            <Button
                                variant="outlined"
                                onClick={
                                    isActive
                                        ? () => startBlocking(row)
                                        : () => handleUnblockWithConfirmation(row)
                                }
                                color={isActive ? 'error' : 'primary'}
                            >
                                {isActive ? 'Block' : 'Unblock'}
                            </Button>
                            <IconButton color="primary" onClick={() => startUpdating(row)}>
                                <PencilSimple weight="bold" />
                            </IconButton>
                            <IconButton
                                color="error"
                                onClick={() => handleDeleteWithConfirmation(row.id)}
                            >
                                <Trash weight="bold" />
                            </IconButton>
                        </Stack>
                    );
                },
                name: 'Actions',
                width: '150px',
                align: 'right',
            },
        ] satisfies ColumnDef<PlayerAdminModel>[];
    }, [startUpdating]);

    const data = tableState.data.items;

    return (
        <TableLoadingWrapper isLoading={tableState.loadStatus === LoadStatus.Loading}>
            <DataTable<PlayerAdminModel> columns={columns} rows={data} />
            {!data.length ? (
                <Box sx={{ p: 3 }}>
                    <Typography color="text.secondary" sx={{ textAlign: 'center' }} variant="body2">
                        No users found
                    </Typography>
                </Box>
            ) : null}
            {!!userToEdit && (
                <UserEditDialog onClose={endUpdating} onApply={update} initialValue={userToEdit} />
            )}
            {!!userToBlock && (
                <BlockUserDialog
                    onConfirm={note => {
                        blockUser(note).then(endBlocking);
                    }}
                    onClose={endBlocking}
                />
            )}
            {!!userToViewNotes && <UserNotesDialog user={userToViewNotes} />}
            <DeleteConfirmation
                title="Are you sure you want to delete this user?"
                primaryActionName="Delete"
            />
            <UnblockConfirmationConfirmation
                title="Are you sure you want to unblock this user?"
                primaryActionName="Unblock"
            />
        </TableLoadingWrapper>
    );
});
