import { observer } from 'mobx-react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useDependencies } from 'ioc';
import { ArrowClockwise } from '@phosphor-icons/react';
import { DebouncedSearch } from 'shared';
import { FilterButton } from '@/components/core/filter-button';
import { PhotoFilterEnum, PlayersStore } from '@/modules/sports/stores/players-store';
import { PhotoFilterPopover } from '@/modules/sports/components/players/filter-popovers/photo-filter-popover';
import { IdNameModel } from '@/modules/common/api/api';
import { TeamFilterPopover } from '@/modules/sports/components/players/filter-popovers/team-filter-popover';

export const PlayersFilters = observer(() => {
    const [
        {
            availableLeagues,
            applyPhotoFilter,
            changeLeague,
            tableState,
            photoFilter,
            selectedLeague,
            selectedTeam,
            applyTeamFilter,
        },
    ] = useDependencies(PlayersStore);

    return (
        <div>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Tabs
                    onChange={(_, value) => {
                        changeLeague(value);
                    }}
                    sx={{ px: 3 }}
                    value={selectedLeague}
                    variant="scrollable"
                    scrollButtons={false}
                >
                    <Tab label="All" sx={{ minHeight: 'auto' }} tabIndex={0} value={null} />
                    {availableLeagues.map(league => (
                        <Tab
                            key={league.id}
                            label={league.name}
                            sx={{ minHeight: 'auto' }}
                            tabIndex={0}
                            value={league.id}
                        />
                    ))}
                </Tabs>
                <Button
                    onClick={() => tableState.fetchData()}
                    endIcon={<ArrowClockwise weight="bold" />}
                    sx={{ mr: 3 }}
                >
                    Refresh
                </Button>
            </Stack>
            <Divider />
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    px: 3,
                    py: 2,
                }}
            >
                <DebouncedSearch placeholder="Search by name" onSearch={tableState.search} />
                <Stack direction="row" spacing={2}>
                    <FilterButton
                        displayValue={photoFilter}
                        onFilterApply={value => {
                            applyPhotoFilter(value as PhotoFilterEnum | undefined);
                        }}
                        onFilterDelete={() => applyPhotoFilter(undefined)}
                        label="Photo"
                        popover={<PhotoFilterPopover />}
                        value={photoFilter}
                    />
                    <FilterButton
                        displayValue={selectedTeam?.name}
                        onFilterApply={value => {
                            applyTeamFilter(value as IdNameModel | undefined);
                        }}
                        onFilterDelete={() => applyTeamFilter(undefined)}
                        label="Team"
                        popover={<TeamFilterPopover />}
                        value={selectedTeam}
                    />
                </Stack>
            </Stack>
        </div>
    );
});
