import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { provide, useDependencies } from 'ioc';
import { TeamsStore } from '@/modules/sports/stores/teams-store';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { TeamsTable } from '@/modules/sports/components/teams/teams-table';
import { TeamsFilters } from '@/modules/sports/components/teams/teams-filters';
import { TeamsPagination } from '@/modules/sports/components/teams/teams-pagination';
import Typography from '@mui/material/Typography';

export const TeamsPage = provide([TeamsStore])(
    observer(() => {
        const [{ init }] = useDependencies(TeamsStore);

        useEffect(() => {
            init();
        }, []);

        return (
            <Box
                sx={{
                    maxWidth: 'var(--Content-maxWidth)',
                    m: 'var(--Content-margin)',
                    p: 'var(--Content-padding)',
                    width: 'var(--Content-width)',
                }}
            >
                <Card>
                    <Typography variant="h5" p={3}>
                        Teams
                    </Typography>
                    <TeamsFilters />
                    <Divider />
                    <Box sx={{ overflowX: 'auto' }}>
                        <TeamsTable />
                    </Box>
                    <Divider />
                    <TeamsPagination />
                </Card>
            </Box>
        );
    }),
);
