import { FC, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { provide, useDependencies } from 'ioc';
import { SportSelectionLeagueItemStore } from '@/modules/common/components/sport-selection-leagues/stores/sport-selection-league-item-store';
import { MatchModel, SportSelectionUnitModel } from '@/modules/common/api/api';
import { dayjs } from '@/lib/dayjs';
import { ColumnDef, DataTable } from '@/components/core/data-table';
import { LoadStatus } from 'shared';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TableLoadingWrapper } from '@/modules/common/components/table-loading-wrapper/table-loading-wrapper';
import { SportSelectionLeaguesStore } from '@/modules/common/components/sport-selection-leagues/stores/sport-selection-leagues-store';

interface ISportSelectionLeagueItemProps {
    unit: SportSelectionUnitModel;
}

export const SportSelectionLeagueItem: FC<ISportSelectionLeagueItemProps> = provide([
    SportSelectionLeagueItemStore,
])(
    observer(({ unit }) => {
        const [{ init, matches, loadStatus }, { leagues }] = useDependencies(
            SportSelectionLeagueItemStore,
            SportSelectionLeaguesStore,
        );

        useEffect(() => {
            init(unit);
        }, []);

        const leagueName = leagues.find(league => league.id === unit.leagueId)?.name ?? '';

        const columns = useMemo(() => {
            return [
                {
                    name: 'Game',
                    width: '380px',
                    formatter: row => row.name,
                },
                {
                    formatter: row => {
                        return row.startDate ? dayjs(row.startDate).format('M/D/YY h:mm A') : '-';
                    },
                    name: 'Date',
                    // width: '150px',
                },
            ] satisfies ColumnDef<MatchModel>[];
        }, []);

        return (
            <Box>
                <Typography variant="subtitle1" sx={{ ml: 2, mb: 1 }}>
                    {leagueName}
                </Typography>
                <TableLoadingWrapper isLoading={loadStatus === LoadStatus.Loading}>
                    <DataTable<MatchModel> columns={columns} rows={matches} />
                    {!matches.length ? (
                        <Box sx={{ p: 3 }}>
                            <Typography
                                color="text.secondary"
                                sx={{ textAlign: 'center' }}
                                variant="body2"
                            >
                                No matches found
                            </Typography>
                        </Box>
                    ) : null}
                </TableLoadingWrapper>
            </Box>
        );
    }),
);
