import { observer } from 'mobx-react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { LogoFilterEnum, TeamsStore } from '@/modules/sports/stores/teams-store';
import Box from '@mui/material/Box';
import { ArrowClockwise } from '@phosphor-icons/react';
import { DebouncedSearch } from 'shared';
import { FilterButton } from '@/components/core/filter-button';
import { LogoFilterPopover } from '@/modules/sports/components/teams/filter-popovers/logo-filter-popover';
import { useDependencies } from 'ioc';

export const TeamsFilters = observer(() => {
    const [
        { availableLeagues, applyLogoFilter, changeLeague, tableState, logoFilter, selectedLeague },
    ] = useDependencies(TeamsStore);

    return (
        <div>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Tabs
                    onChange={(_, value) => {
                        changeLeague(value);
                    }}
                    sx={{ px: 3 }}
                    value={selectedLeague}
                    variant="scrollable"
                    scrollButtons={false}
                >
                    <Tab label="All" sx={{ minHeight: 'auto' }} tabIndex={0} value={null} />
                    {availableLeagues.map(league => (
                        <Tab
                            key={league.id}
                            label={league.name}
                            sx={{ minHeight: 'auto' }}
                            tabIndex={0}
                            value={league.id}
                        />
                    ))}
                </Tabs>
                <Button
                    onClick={() => tableState.fetchData()}
                    endIcon={<ArrowClockwise weight="bold" />}
                    sx={{ mr: 3 }}
                >
                    Refresh
                </Button>
            </Stack>
            <Divider />
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    px: 3,
                    py: 2,
                }}
            >
                <DebouncedSearch placeholder="Search by name" onSearch={tableState.search} />
                <Box>
                    <FilterButton
                        displayValue={logoFilter}
                        onFilterApply={value => {
                            applyLogoFilter(value as LogoFilterEnum | undefined);
                        }}
                        onFilterDelete={() => applyLogoFilter(undefined)}
                        label="Logo"
                        popover={<LogoFilterPopover />}
                        value={logoFilter}
                    />
                </Box>
            </Stack>
        </div>
    );
});
