import { Fragment, useMemo } from 'react';
import { observer } from 'mobx-react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { dayjs } from '@/lib/dayjs';
import { ColumnDef, DataTable } from '@/components/core/data-table';
import { SportPlayerModel } from '@/modules/common/api/api';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useDependencies } from 'ioc';
import { LoadStatus } from 'shared';
import { ImageUploader } from '@/modules/common/components/image-uploader/image-uploader';
import Avatar from '@mui/material/Avatar';
import { PlayersStore } from '@/modules/sports/stores/players-store';
import { TableLoadingWrapper } from '@/modules/common/components/table-loading-wrapper/table-loading-wrapper';

export const PlayersTable = observer(() => {
    const [
        {
            tableState,
            playerIdToUploadLogo,
            startPhotoUploading,
            endPhotoUploading,
            uploadPhotoDirectly,
            updatePhotoURL,
        },
    ] = useDependencies(PlayersStore);

    const columns = useMemo(
        () =>
            [
                {
                    formatter: row => (
                        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                            <Avatar src={row.logo?.url} />
                            <Typography variant="inherit">{row.name}</Typography>
                        </Stack>
                    ),
                    name: 'Player',
                    width: '250px',
                },
                {
                    formatter: row => row.league?.name,
                    name: 'League',
                    width: '250px',
                },
                {
                    formatter: row => row.team?.name,
                    name: 'Team',
                    width: '250px',
                },
                {
                    formatter: row =>
                        row.logo?.uploadDate
                            ? dayjs(row.logo?.uploadDate).format('M/D/YY h:mm A')
                            : '-',
                    name: 'Modified at',
                    width: '200px',
                },
                {
                    formatter: row => {
                        return (
                            <Button variant="outlined" onClick={() => startPhotoUploading(row.id)}>
                                Upload Photo
                            </Button>
                        );
                    },
                    name: 'Actions',
                    width: '100px',
                    align: 'right',
                },
            ] satisfies ColumnDef<SportPlayerModel>[],
        [startPhotoUploading],
    );

    const data = tableState.data.items;

    return (
        <TableLoadingWrapper isLoading={tableState.loadStatus === LoadStatus.Loading}>
            <DataTable<SportPlayerModel> columns={columns} rows={data} />
            {!data.length ? (
                <Box sx={{ p: 3 }}>
                    <Typography color="text.secondary" sx={{ textAlign: 'center' }} variant="body2">
                        No players found
                    </Typography>
                </Box>
            ) : null}
            {playerIdToUploadLogo !== undefined && (
                <ImageUploader
                    onClose={endPhotoUploading}
                    onDirectUpload={file => {
                        uploadPhotoDirectly(playerIdToUploadLogo, {
                            fileName: file.name,
                            data: file,
                        });
                    }}
                    onURLUpload={url => {
                        updatePhotoURL(playerIdToUploadLogo, url);
                    }}
                />
            )}
        </TableLoadingWrapper>
    );
});
