import { injectable } from 'ioc';
import { makeAutoObservable, runInAction } from 'mobx';
import { FieldState, FormState } from 'formstate';
import {
    ApplicationFileModel,
    FileParameter,
    GameTemplatePublicModel,
    GameTemplateUpsertModel,
    IdNameModel,
    ImageUrlUploadModel,
    SportSelectionUnitModel,
    TimeRange,
} from '@/modules/common/api/api';
import { apiClient } from '@/modules/common/api/api-client';
import { formStateValidators, noop } from 'shared';
import { getSportSelectionUnitFormState } from '@/modules/game-templates/utils/get-sport-selection-unit-form-state';
import { SportSelectionType } from '@/modules/game-templates/utils/sport-selection-types';

@injectable()
export class GameTemplateEditStore {
    formState = new FormState({
        logo: new FieldState<Pick<ApplicationFileModel, 'id' | 'url'>>({
            id: '',
            url: '',
        }).validators(value => formStateValidators.required(value.id)),
        name: new FieldState('').validators(formStateValidators.required),
        leagues: new FormState(
            new Map<number, ReturnType<typeof getSportSelectionUnitFormState>>(),
        ),
    });

    availableLeagues: IdNameModel[] = [];
    isUploadingLogo = false;
    openedSportSelectionUnitId?: number;
    private lastUploadedLogoId?: ApplicationFileModel['id'];

    constructor() {
        this.formState.disableAutoValidation();
        makeAutoObservable(this);
    }

    init = async (gameTemplate?: GameTemplatePublicModel) => {
        try {
            this.fetchLeagues().catch(noop);

            if (gameTemplate) {
                const template = await apiClient.gameTemplatesGET(gameTemplate.id);

                const { logo, name, leagues } = gameTemplate;

                this.formState.$.logo.onChange(logo ?? { id: '', url: '' });
                this.formState.$.name.onChange(name);

                if (template.leagues.length) {
                    template.leagues.forEach(sportSelectionUnit => {
                        this.formState.$.leagues.$.set(
                            Math.random(),
                            getSportSelectionUnitFormState(sportSelectionUnit),
                        );
                    });
                }
            }

            if (!this.formState.$.leagues.$.size) {
                const key = Math.random();

                this.formState.$.leagues.$.set(key, getSportSelectionUnitFormState());
                this.setOpenedSportSelectionUnitId(key);
            }
        } catch {
            //
        }
    };

    setOpenedSportSelectionUnitId = (id?: number) => {
        this.openedSportSelectionUnitId = id;
    };

    startLogoUploading = () => {
        this.isUploadingLogo = true;
    };

    endLogoUploading = () => {
        this.isUploadingLogo = false;
    };

    removeLastUploadedLogo = () => {
        if (this.lastUploadedLogoId) {
            apiClient.deleteFile(this.lastUploadedLogoId).catch(noop);
        }
    };

    uploadLogoDirectly = async (file: FileParameter) => {
        try {
            const newFile = await apiClient.uploadFile(undefined, file);

            this.formState.$.logo.onChange(newFile);
            this.removeLastUploadedLogo();
            this.lastUploadedLogoId = newFile.id;

            this.endLogoUploading();
        } catch {
            // skip
        }
    };

    uploadLogoByURL = async (url: string) => {
        try {
            const newFile = await apiClient.uploadFileByUrl(
                undefined,
                new ImageUrlUploadModel({ url }),
            );

            this.formState.$.logo.onChange(newFile);
            this.removeLastUploadedLogo();
            this.lastUploadedLogoId = newFile.id;

            this.endLogoUploading();
        } catch {
            // skip
        }
    };

    getValidatedData = async () => {
        await this.formState.enableAutoValidationAndValidate();

        if (this.formState.hasError) {
            throw new Error('Validation error');
        }

        const formStateValues = this.formState.$;

        return new GameTemplateUpsertModel({
            name: formStateValues.name.$,
            logoId: formStateValues.logo.$.id,
            leagues: Array.from(formStateValues.leagues.$.values()).map(
                formState =>
                    new SportSelectionUnitModel({
                        leagueId: formState.$.leagueId.value!,
                        timeRange:
                            formState.$.type.value === SportSelectionType.Dates
                                ? new TimeRange({
                                      startDate: formState.$.startDate.value!,
                                      endDate: formState.$.endDate.value!,
                                  })
                                : undefined,
                        matchIds:
                            formState.$.type.value === SportSelectionType.Matches
                                ? Array.from(formState.$.matchIds.value)
                                : [],
                    }),
            ),
        });
    };

    fetchLeagues = async () => {
        try {
            const { items: availableLeagues } = await apiClient.getSportLeagues(
                undefined,
                undefined,
                undefined,
            );

            runInAction(() => {
                this.availableLeagues = availableLeagues;
            });
        } catch {
            //
        }
    };

    addLeague = () => {
        const key = Math.random();

        this.formState.$.leagues.$.set(key, getSportSelectionUnitFormState());
        this.setOpenedSportSelectionUnitId(key);
    };

    removeLeague = (id: number) => {
        this.formState.$.leagues.$.delete(id);
    };

    get usedLeagueIds() {
        return Array.from(this.formState.$.leagues.$.values()).map(
            formState => formState.$.leagueId.value,
        );
    }

    get registrationDeadline() {
        const sportSelectionUnits = Array.from(this.formState.$.leagues.$.values());

        if (!sportSelectionUnits.every(item => !!item.$.registrationDeadline.value)) {
            return undefined;
        }

        const deadlineTimestamp = sportSelectionUnits.reduce((acc, val) => {
            if (!val.$.registrationDeadline.value) {
                return acc;
            }

            return Math.min(acc, val.$.registrationDeadline.value.getTime());
        }, Infinity);

        return deadlineTimestamp !== Infinity ? new Date(deadlineTimestamp) : undefined;
    }
}
