import { inject, injectable } from 'ioc';
import { AuthStore } from '@/modules/app/stores/auth-store';
import { FieldState, FormState } from 'formstate';
import { formStateValidators, LoadStatus } from 'shared';
import { makeAutoObservable, runInAction } from 'mobx';
import { IUserLoginModel, UserLoginModel } from '@/modules/common/api/api';
import { apiClient } from '@/modules/common/api/api-client';

@injectable()
export class SignInStore {
    @inject(AuthStore) private readonly authStore!: AuthStore;

    formState = new FormState({
        email: new FieldState('').validators(formStateValidators.required),
        password: new FieldState('').validators(formStateValidators.required),
    });
    signInLoadStatus = LoadStatus.None;

    constructor() {
        this.formState.disableAutoValidation();
        makeAutoObservable(this);
    }

    signIn = async () => {
        try {
            runInAction(() => {
                this.signInLoadStatus = LoadStatus.Loading;
            });

            await this.formState.enableAutoValidationAndValidate();

            if (this.formState.hasError) {
                throw new Error('Validation error');
            }

            const payload: IUserLoginModel = {
                login: this.formState.$.email.$,
                password: this.formState.$.password.$,
            };

            const jwtAuthResult = await apiClient.accessToken(new UserLoginModel(payload));

            this.authStore.authorize(jwtAuthResult);
        } catch {
            runInAction(() => {
                this.signInLoadStatus = LoadStatus.Error;
            });

            throw new Error('Unable to _login');
        }
    };
}
