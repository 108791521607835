import { InputAdornment, TextFieldProps } from '@mui/material';
import { ChangeEvent, FC, useMemo } from 'react';
import debounce from 'lodash.debounce';
import TextField from '@mui/material/TextField';
import { MagnifyingGlass } from '@phosphor-icons/react';

interface DebouncedSearchProps extends Omit<TextFieldProps, 'variant'> {
    onSearch: (term: string) => void;
    width?: number;
    delay?: number;
}

export const DebouncedSearch: FC<DebouncedSearchProps> = ({
    onSearch,
    width,
    delay = 1_000,
    ...props
}) => {
    const handleDebouncedSearch = useMemo(
        () =>
            debounce((event: ChangeEvent<HTMLInputElement>) => {
                onSearch(event.target.value);
            }, delay),
        [delay, onSearch],
    );

    return (
        <TextField
            margin="none"
            onChange={handleDebouncedSearch}
            size="small"
            label="Search"
            {...props}
            slotProps={{
                input: {
                    endAdornment: (
                        <InputAdornment position="end">
                            <MagnifyingGlass />
                        </InputAdornment>
                    ),
                    sx: {
                        width,
                    },
                },
            }}
        />
    );
};
