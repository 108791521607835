import { provide, useDependencies } from 'ioc';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { PlusCircle } from '@phosphor-icons/react';
import { RankedGamesStore } from '@/modules/ranked-games/stores/ranked-games-store';
import { RankedGamesPagination } from '@/modules/ranked-games/components/ranked-games-content/ranked-games-pagination';
import { RankedGamesTable } from '@/modules/ranked-games/components/ranked-games-content/ranked-games-table';
import { RankedGamesFilters } from '@/modules/ranked-games/components/ranked-games-content/ranked-games-filters';

export const RankedGamesPage = provide([RankedGamesStore])(
    observer(() => {
        const [{ init, startTemplateCreating }] = useDependencies(RankedGamesStore);

        useEffect(() => {
            init();
        }, []);

        return (
            <Box
                sx={{
                    maxWidth: 'var(--Content-maxWidth)',
                    m: 'var(--Content-margin)',
                    p: 'var(--Content-padding)',
                    width: 'var(--Content-width)',
                }}
            >
                <Card>
                    <Stack p={3} justifyContent="space-between" direction="row">
                        <Typography variant="h5">Ranked Games</Typography>
                        <Button
                            variant="contained"
                            endIcon={<PlusCircle weight="bold" />}
                            onClick={startTemplateCreating}
                        >
                            Create Ranked Game
                        </Button>
                    </Stack>
                    <RankedGamesFilters />
                    <Divider />
                    <Box sx={{ overflowX: 'auto' }}>
                        <RankedGamesTable />
                    </Box>
                    <Divider />
                    <RankedGamesPagination />
                </Card>
            </Box>
        );
    }),
);
