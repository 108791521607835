import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { provide, useDependencies } from 'ioc';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { PlayersPagination } from '@/modules/sports/components/players/players-pagination';
import { PlayersTable } from '@/modules/sports/components/players/players-table';
import { PlayersFilters } from '@/modules/sports/components/players/players-filters';
import { PlayersStore } from '@/modules/sports/stores/players-store';

export const PlayersPage = provide([PlayersStore])(
    observer(() => {
        const [{ init }] = useDependencies(PlayersStore);

        useEffect(() => {
            init();
        }, []);

        return (
            <Box
                sx={{
                    maxWidth: 'var(--Content-maxWidth)',
                    m: 'var(--Content-margin)',
                    p: 'var(--Content-padding)',
                    width: 'var(--Content-width)',
                }}
            >
                <Card>
                    <Typography variant="h5" p={3}>
                        Players
                    </Typography>
                    <PlayersFilters />
                    <Divider />
                    <Box sx={{ overflowX: 'auto' }}>
                        <PlayersTable />
                    </Box>
                    <Divider />
                    <PlayersPagination />
                </Card>
            </Box>
        );
    }),
);
