import { observer } from 'mobx-react';
import { useDependencies } from 'ioc';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import { ArrowClockwise } from '@phosphor-icons/react';
import Divider from '@mui/material/Divider';

import { UserStatusEnum } from '@/modules/common/api/api';
import { UsersStore } from '@/modules/users/stores/users-store';
import { DebouncedSearch } from 'shared';
import Box from '@mui/material/Box';

export const UsersFilters = observer(() => {
    const [{ statusFilter, applyStatusFilter, tableState }] = useDependencies(UsersStore);

    return (
        <Box>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Tabs
                    onChange={(_, value) => {
                        applyStatusFilter(value ?? undefined);
                    }}
                    sx={{ px: 3 }}
                    value={statusFilter ?? null}
                    variant="scrollable"
                    scrollButtons={false}
                >
                    <Tab label="All" tabIndex={0} value={null} />
                    <Tab label={UserStatusEnum.Active} tabIndex={0} value={UserStatusEnum.Active} />
                    <Tab
                        label={UserStatusEnum.Blocked}
                        tabIndex={0}
                        value={UserStatusEnum.Blocked}
                    />
                </Tabs>
                <Button
                    onClick={() => tableState.fetchData()}
                    endIcon={<ArrowClockwise weight="bold" />}
                    sx={{ mr: 3 }}
                >
                    Refresh
                </Button>
            </Stack>
            <Divider />
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    alignItems: 'center',
                    px: 3,
                    py: 2,
                }}
            >
                <DebouncedSearch
                    placeholder="Search by name, username"
                    onSearch={tableState.search}
                    sx={{ width: 250 }}
                />
            </Stack>
        </Box>
    );
});
