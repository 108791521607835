import { observer } from 'mobx-react';
import { useDependencies } from 'ioc';
import { UsersStore } from '@/modules/users/stores/users-store';
import { CommonTablePagination } from '@/modules/common/components/common-table-pagination/common-table-pagination';

export const UsersPagination = observer(() => {
    const [{ tableState }] = useDependencies(UsersStore);

    return <CommonTablePagination tableState={tableState} />;
});
