import { ChangeEvent, Fragment, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { FilterPopover, useFilterContext } from '@/components/core/filter-button';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useDependencies } from 'ioc';
import { LoadStatus } from 'shared';
import { BetGraderStore } from '@/modules/bet-grader/stores/bet-grader-store';
import { IdNameModel } from '@/modules/common/api/api';
import debounce from 'lodash.debounce';

export const MatchesFilterPopover = observer(() => {
    const { anchorEl, onApply, onClose, open, value: initialValue } = useFilterContext();
    const [value, setValue] = useState((initialValue as IdNameModel | undefined) ?? null);

    useEffect(() => {
        setValue((initialValue as IdNameModel | undefined) ?? null);
    }, [initialValue]);

    const [{ matchesLoadStatus, fetchMatches, setMatches, availableMatches }] =
        useDependencies(BetGraderStore);

    const handleOpenOptions = () => {
        setMatches([]);
        fetchMatches();
    };

    const handleCloseOptions = () => {
        setMatches([]);
    };

    const handleDebouncedSearch = useMemo(
        () =>
            debounce((event: ChangeEvent<HTMLInputElement>) => {
                fetchMatches(event.target.value);
            }, 1_000),
        [fetchMatches],
    );

    const handleClose = () => {
        setValue((initialValue as IdNameModel | undefined) ?? null);
        onClose();
    };

    return (
        <FilterPopover
            anchorEl={anchorEl}
            onClose={handleClose}
            open={open}
            title="Filter by Matches"
        >
            <Autocomplete
                // @ts-expect-error to remain controlled
                value={value}
                disableClearable
                onChange={(_, newValue) => {
                    setValue(newValue);
                }}
                filterOptions={x => x}
                isOptionEqualToValue={(option, currentValue) => option.id === currentValue.id}
                getOptionLabel={option => option.name ?? ''}
                options={availableMatches}
                loading={matchesLoadStatus === LoadStatus.Loading}
                onOpen={handleOpenOptions}
                onClose={handleCloseOptions}
                renderInput={params => (
                    <TextField
                        {...params}
                        onChange={handleDebouncedSearch}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <Fragment>
                                    {matchesLoadStatus === LoadStatus.Loading ? (
                                        <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </Fragment>
                            ),
                        }}
                    />
                )}
            />
            <Button
                onClick={() => {
                    onApply(value ?? undefined);
                }}
                variant="contained"
            >
                Apply
            </Button>
        </FilterPopover>
    );
});
