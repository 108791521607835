import { injectable } from 'ioc';
import { makeAutoObservable, runInAction } from 'mobx';
import { LoadStatus, noop, TableState, ITableStateDataGetter } from 'shared';
import {
    PlayerAdminModel,
    PlayerNoteUpsertModel,
    PlayerStatusUpdateModel,
    PlayerUpdateModel,
    UserStatusEnum,
} from '@/modules/common/api/api';
import { apiClient } from '@/modules/common/api/api-client';

@injectable()
export class UsersStore {
    tableState: TableState<PlayerAdminModel>;
    userToEdit?: PlayerAdminModel;
    userToBlock?: PlayerAdminModel;
    userToViewNotes?: PlayerAdminModel;
    statusFilter?: PlayerAdminModel['status'];

    constructor() {
        this.tableState = new TableState(this.getData);
        makeAutoObservable(this);
    }

    init = async () => {
        try {
            await this.tableState.fetchData();
        } catch {
            //
        }
    };

    applyStatusFilter = async (status: typeof this.statusFilter) => {
        runInAction(() => {
            this.statusFilter = status;
        });

        await this.tableState.fetchData({ page: 0 });
    };

    startUpdating = (user: PlayerAdminModel) => {
        this.userToEdit = user;
    };

    endUpdating = () => {
        this.userToEdit = undefined;
    };

    update = async (payload: PlayerUpdateModel) => {
        if (!this.userToEdit) {
            return;
        }

        try {
            await apiClient.playersPUT(this.userToEdit.id, payload);

            this.tableState.fetchData().catch(noop);

            this.endUpdating();
        } catch {
            // skip
        }
    };

    deleteUser = async (id: PlayerAdminModel['id']) => {
        try {
            await apiClient.changePlayerStatus(
                id,
                new PlayerStatusUpdateModel({
                    status: UserStatusEnum.Deleted,
                    noteText: undefined,
                }),
            );

            this.tableState.fetchData().catch(noop);
        } catch {
            //
        }
    };

    startViewingNotes = (user: PlayerAdminModel) => {
        this.userToViewNotes = user;
    };

    endViewingNotes = () => {
        this.userToViewNotes = undefined;
    };

    addNote = async (noteText: string) => {
        if (!this.userToViewNotes) {
            return;
        }

        const userToViewNotes = this.userToViewNotes;

        try {
            const note = await apiClient.addPlayerNotes(
                this.userToViewNotes.id,
                new PlayerNoteUpsertModel({
                    noteText,
                }),
            );

            runInAction(() => {
                userToViewNotes.notes.unshift(note);

                // to rerender
                this.userToViewNotes = new PlayerAdminModel(this.userToViewNotes);
            });
        } catch {
            //
        }
    };

    startBlocking = (user: PlayerAdminModel) => {
        this.userToBlock = user;
    };

    endBlocking = () => {
        this.userToBlock = undefined;
    };

    blockUser = async (noteText: string) => {
        if (!this.userToBlock) {
            return;
        }

        try {
            await apiClient.changePlayerStatus(
                this.userToBlock.id,
                new PlayerStatusUpdateModel({
                    status: UserStatusEnum.Blocked,
                    noteText,
                }),
            );

            this.tableState.fetchData().catch(noop);
        } catch {
            //
        }
    };

    unblockUser = async (user: PlayerAdminModel) => {
        try {
            await apiClient.changePlayerStatus(
                user.id,
                new PlayerStatusUpdateModel({
                    status: UserStatusEnum.Active,
                    noteText: undefined,
                }),
            );

            this.tableState.fetchData().catch(noop);
        } catch {
            //
        }
    };

    getData: ITableStateDataGetter<PlayerAdminModel> = async (filters, abortSignal) => {
        const data = await apiClient.getPlayers(
            filters.searchTerm,
            undefined,
            this.statusFilter,
            Math.round(filters.page * filters.rowsPerPage),
            filters.rowsPerPage,
            abortSignal,
        );

        return {
            items: data.items ?? [],
            total: data.totalAmount,
        };
    };
}
