import { observer } from 'mobx-react';
import { useDependencies } from 'ioc';
import { BetGraderStore } from '@/modules/bet-grader/stores/bet-grader-store';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import { ArrowClockwise } from '@phosphor-icons/react';
import Divider from '@mui/material/Divider';

import { FilterButton } from '@/components/core/filter-button';
import { MatchesFilterPopover } from '@/modules/bet-grader/components/bet-grader-content/filter-popovers/matches-filter-popover';
import { IdNameModel, MatchOddStatus, OddGradingSystem } from '@/modules/common/api/api';
import { MarketFilterPopover } from '@/modules/bet-grader/components/bet-grader-content/filter-popovers/market-filter-popover';
import { BetNameFilterPopover } from '@/modules/bet-grader/components/bet-grader-content/filter-popovers/bet-name-filter-popover';
import { GraderFilterPopover } from '@/modules/bet-grader/components/bet-grader-content/filter-popovers/grader-filter-popover';
import { ResultFilterPopover } from '@/modules/bet-grader/components/bet-grader-content/filter-popovers/result-filter-popover';

export const BetGraderFilters = observer(() => {
    const [
        {
            availableLeagues,
            hasFilters,
            changeLeague,
            tableState,
            selectedMatch,
            selectedLeague,
            applyMatchFilter,
            selectedMarket,
            applyMarketFilter,
            clearFilters,
            isPending,
            graderFilter,
            applyGraderFilter,
            resultFilter,
            applyResultFilter,
        },
    ] = useDependencies(BetGraderStore);

    return (
        <div>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Tabs
                    onChange={(_, value) => {
                        changeLeague(value);
                    }}
                    sx={{ px: 3 }}
                    value={selectedLeague}
                    variant="scrollable"
                    scrollButtons={false}
                >
                    <Tab label="All" sx={{ minHeight: 'auto' }} tabIndex={0} value={null} />
                    {availableLeagues.map(league => (
                        <Tab
                            key={league.id}
                            label={league.name}
                            sx={{ minHeight: 'auto' }}
                            tabIndex={0}
                            value={league.id}
                        />
                    ))}
                </Tabs>
                <Button
                    onClick={() => tableState.fetchData()}
                    endIcon={<ArrowClockwise weight="bold" />}
                    sx={{ mr: 3 }}
                >
                    Refresh
                </Button>
            </Stack>
            <Divider />
            <Stack
                direction="row"
                spacing={2}
                sx={{ alignItems: 'center', flexWrap: 'wrap', px: 3, py: 2 }}
            >
                <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                        alignItems: 'center',
                        flex: '1 1 auto',
                        flexWrap: 'wrap',
                    }}
                >
                    <FilterButton
                        displayValue={selectedMatch?.name}
                        label="Matches"
                        popover={<MatchesFilterPopover />}
                        value={selectedMatch}
                        onFilterDelete={() => applyMatchFilter(undefined)}
                        onFilterApply={value => {
                            applyMatchFilter(value as IdNameModel | undefined);
                        }}
                    />
                    <FilterButton
                        displayValue={selectedMarket?.name}
                        label="Market"
                        popover={<MarketFilterPopover />}
                        value={selectedMarket}
                        onFilterDelete={() => applyMarketFilter(undefined)}
                        onFilterApply={value => {
                            applyMarketFilter(value as IdNameModel | undefined);
                        }}
                    />
                    <FilterButton
                        displayValue={tableState.filters.searchTerm}
                        label="Bet Name"
                        popover={<BetNameFilterPopover />}
                        value={tableState.filters.searchTerm}
                        onFilterDelete={() => tableState.search('')}
                        onFilterApply={value => {
                            tableState.search(value as string);
                        }}
                    />
                    {!isPending && (
                        <FilterButton
                            displayValue={graderFilter}
                            label="Grader"
                            popover={<GraderFilterPopover />}
                            value={graderFilter}
                            onFilterDelete={() => applyGraderFilter(undefined)}
                            onFilterApply={value => {
                                applyGraderFilter(value as OddGradingSystem | undefined);
                            }}
                        />
                    )}
                    {!isPending && (
                        <FilterButton
                            displayValue={resultFilter}
                            label="Result"
                            popover={<ResultFilterPopover />}
                            value={resultFilter}
                            onFilterDelete={() => applyResultFilter(undefined)}
                            onFilterApply={value => {
                                applyResultFilter(value as MatchOddStatus | undefined);
                            }}
                        />
                    )}
                    {hasFilters && <Button onClick={clearFilters}>Clear filters</Button>}
                </Stack>
            </Stack>
        </div>
    );
});
