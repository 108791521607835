import { makeAutoObservable, runInAction, when } from 'mobx';
import { LoadStatus } from '../enums';
import axios from 'axios';
import { rowsPerPageOptions } from './constants';
import { ITableStateData, ITableStateDataGetter, ITableStateFilters } from './types';

export class TableState<T> {
    filters: ITableStateFilters = {
        page: 0,
        searchTerm: '',
        rowsPerPage: 5,
    };
    rowsPerPageOptions = rowsPerPageOptions;
    data: ITableStateData<T> = {
        items: [],
        total: 0,
    };

    abortController = new AbortController();
    loadStatus = LoadStatus.None;
    getData;

    constructor(getData: ITableStateDataGetter<T>) {
        this.getData = getData;
        makeAutoObservable(this);
    }

    fetchData = async (newFilters?: Partial<ITableStateFilters>) => {
        this.abortController.abort();
        await when(() => this.loadStatus !== LoadStatus.Loading);
        this.abortController = new AbortController();

        runInAction(() => {
            this.loadStatus = LoadStatus.Loading;
        });

        if (newFilters) {
            runInAction(() => {
                this.filters = {
                    ...this.filters,
                    ...newFilters,
                };
            });
        }

        try {
            const data = await this.getData(this.filters, this.abortController.signal);

            runInAction(() => {
                this.data = data;
                this.loadStatus = LoadStatus.Ok;
            });
        } catch (error) {
            if (axios.isCancel(error)) {
                runInAction(() => {
                    this.loadStatus = LoadStatus.Ok;
                });

                return;
            }

            runInAction(() => {
                this.loadStatus = LoadStatus.Error;
                this.data = { items: [], total: 0 };
            });
        }
    };

    search = async (searchTerm: string) => {
        runInAction(() => {
            this.filters.page = 0;
            this.filters.searchTerm = searchTerm;
        });

        await this.fetchData();
    };

    changePage = async (page: typeof this.filters.page) => {
        runInAction(() => {
            this.filters.page = page;
        });

        await this.fetchData(this.filters);
    };

    changeRowsPerPage = async (rowsPerPage: typeof this.filters.rowsPerPage) => {
        runInAction(() => {
            this.filters.page = 0;
            this.filters.rowsPerPage = rowsPerPage;
        });

        await this.fetchData(this.filters);
    };
}
