import { makeAutoObservable, runInAction } from 'mobx';
import { MatchModel, SportSelectionUnitModel } from '@/modules/common/api/api';
import { apiClient } from '@/modules/common/api/api-client';
import { injectable } from 'ioc';
import { LoadStatus } from 'shared';

@injectable()
export class SportSelectionLeagueItemStore {
    matches: MatchModel[] = [];
    loadStatus = LoadStatus.None;

    constructor() {
        makeAutoObservable(this);
    }

    init = async (sportSelectionUnit: SportSelectionUnitModel) => {
        try {
            runInAction(() => {
                this.loadStatus = LoadStatus.Loading;
            });

            const allMatches = await apiClient.getMatchesAdmin(
                sportSelectionUnit.leagueId,
                undefined,
                undefined,
                undefined,
                0,
                0,
            );

            const sportSelectionUnitMatches = allMatches.items.filter(({ id }) =>
                sportSelectionUnit.matchIds.includes(id),
            );

            runInAction(() => {
                this.matches = sportSelectionUnitMatches;
                this.loadStatus = LoadStatus.Ok;
            });
        } catch {
            runInAction(() => {
                this.loadStatus = LoadStatus.Error;
            });
        }
    };
}
