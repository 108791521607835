import 'reflect-metadata';

import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import { App } from '@/modules/app/components/app';

const root = createRoot(document.getElementById('root')!);

root.render(
    // <StrictMode>
    <App />,
    // </StrictMode>
);
