import { makeAutoObservable, runInAction } from 'mobx';
import { SportLeagueModel } from '@/modules/common/api/api';
import { apiClient } from '@/modules/common/api/api-client';
import { injectable } from 'ioc';
import { LoadStatus } from 'shared';

@injectable()
export class SportSelectionLeaguesStore {
    leagues: SportLeagueModel[] = [];
    loadStatus = LoadStatus.None;

    constructor() {
        makeAutoObservable(this);
    }

    init = async () => {
        try {
            runInAction(() => {
                this.loadStatus = LoadStatus.Loading;
            });

            const { items: leagues } = await apiClient.getSportLeagues(
                undefined,
                undefined,
                undefined,
            );

            runInAction(() => {
                this.leagues = leagues;
                this.loadStatus = LoadStatus.Ok;
            });
        } catch {
            runInAction(() => {
                this.loadStatus = LoadStatus.Error;
            });
        }
    };
}
