import { observer } from 'mobx-react';
import { provide, useDependencies } from 'ioc';
import { LoadStatus } from 'shared';
import { SignInStore } from '@/modules/auth/sign-in/stores/sign-in-store';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { noop } from 'app/src/modules/common/utils/noop';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button';
import { CenteredLayout } from '@/components/auth/centered-layout';

export const SignInPage = provide([SignInStore])(
    observer(() => {
        const [{ formState, signInLoadStatus, signIn }] = useDependencies(SignInStore);

        const [searchParams] = useSearchParams();

        const { email, password } = formState.$;
        const navigate = useNavigate();

        const handleSubmit = () => {
            signIn()
                .then(() => {
                    const returnURL = searchParams.get('returnURL');

                    if (returnURL) {
                        navigate(decodeURIComponent(returnURL));
                    }
                })
                .catch(noop);
        };

        return (
            <CenteredLayout>
                <Stack spacing={4}>
                    <Card>
                        <CardHeader
                            subheader={
                                <Typography color="text.secondary" variant="body2">
                                    Log in on the internal platform
                                </Typography>
                            }
                            title={<Typography variant="h4">Log in</Typography>}
                        />
                        <CardContent>
                            <Stack spacing={2}>
                                <Stack
                                    spacing={2}
                                    component="form"
                                    onSubmit={event => {
                                        event.preventDefault();
                                        handleSubmit();
                                    }}
                                >
                                    <FormControl>
                                        <InputLabel>Email Address</InputLabel>
                                        <OutlinedInput
                                            name="email"
                                            type="email"
                                            value={email.value}
                                            error={email.hasError}
                                            onChange={event => {
                                                email.onChange(event.target.value);
                                            }}
                                            onBlur={email.enableAutoValidationAndValidate}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <InputLabel>Password</InputLabel>
                                        <OutlinedInput
                                            name="password"
                                            type="password"
                                            value={password.value}
                                            error={password.hasError}
                                            onChange={event => {
                                                password.onChange(event.target.value);
                                            }}
                                            onBlur={password.enableAutoValidationAndValidate}
                                        />
                                    </FormControl>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        disabled={
                                            formState.hasError ||
                                            signInLoadStatus === LoadStatus.Loading
                                        }
                                    >
                                        Log In
                                    </Button>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>
            </CenteredLayout>
        );
    }),
);
