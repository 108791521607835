import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { getSportSelectionUnitFormState } from '@/modules/game-templates/utils/get-sport-selection-unit-form-state';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { provide, useDependencies } from 'ioc';
import { SportSelectionUnitStore } from '@/modules/sport-selection-unit/stores/sport-selection-unit-store';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { EditFormItem } from '@/modules/common/components/edit-form-item/edit-form-item';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { dayjs } from '@/lib/dayjs';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { Trash as TrashIcon } from '@phosphor-icons/react';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { SportSelectionType } from '@/modules/game-templates/utils/sport-selection-types';
import Box from '@mui/material/Box';
import { SportSelectionMatches } from '@/modules/sport-selection-unit/components/sport-selection-matches';
import { INVALID_DATE_RANGE_ERROR } from 'shared';
import { FormState } from 'formstate';
import { IdNameModel } from '@/modules/common/api/api';

interface ISportSelectionUnitProps {
    index: number;
    id: number;
    formState: ReturnType<typeof getSportSelectionUnitFormState>;
    leaguesFormState: FormState<any>;
    openedSportSelectionUnitId?: number;
    onRemoveLeague: (id: number) => void;
    setOpenedSportSelectionUnitId: (id: number | undefined) => void;
    usedLeagueIds: (number | undefined)[];
    availableLeagues: IdNameModel[];
}

export const SportSelectionUnit: FC<ISportSelectionUnitProps> = provide([SportSelectionUnitStore])(
    observer(
        ({
            formState,
            id,
            index,
            leaguesFormState,
            setOpenedSportSelectionUnitId,
            openedSportSelectionUnitId,
            onRemoveLeague,
            availableLeagues,
            usedLeagueIds,
        }) => {
            const [{ init, selectLeague }] = useDependencies(SportSelectionUnitStore);

            useEffect(() => {
                init(formState);
            }, []);

            const { endDate, startDate, leagueId, type } = formState.$;

            const handleRemove = () => {
                onRemoveLeague(id);
            };

            const availableLeaguesToSelect = availableLeagues.filter(
                league =>
                    !usedLeagueIds.includes(league.id) || league.id === formState.$.leagueId.value,
            );

            const leagueName =
                availableLeagues.find(league => league.id === formState.$.leagueId.value)?.name ??
                `League ${index + 1}`;

            return (
                <Accordion
                    elevation={0}
                    expanded={openedSportSelectionUnitId === id}
                    onChange={(_, expanded) => {
                        setOpenedSportSelectionUnitId(expanded ? id : undefined);
                    }}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography
                            fontWeight={700}
                            color={formState.hasError ? 'error' : undefined}
                        >
                            {leagueName}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Card component={Stack} divider={<Divider />}>
                            <EditFormItem name="Choose League" required>
                                <Select
                                    value={leagueId.value ?? ''}
                                    onChange={e => {
                                        selectLeague(e.target.value as number);
                                    }}
                                    input={<OutlinedInput fullWidth />}
                                    MenuProps={{
                                        slotProps: {
                                            paper: {
                                                style: {
                                                    maxHeight: 210,
                                                    width: 350,
                                                },
                                                sx: {
                                                    '& ul': {
                                                        paddingRight: '8px !important',
                                                    },
                                                },
                                            },
                                        },
                                    }}
                                >
                                    {availableLeaguesToSelect.map(({ name: leagueName, id }) => (
                                        <MenuItem key={id} value={id}>
                                            {leagueName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </EditFormItem>
                            <EditFormItem name="Type" required>
                                <RadioGroup
                                    value={type.value}
                                    name="type"
                                    row
                                    onChange={(_, newValue: SportSelectionType) => {
                                        type.onChange(newValue);
                                    }}
                                >
                                    {Object.values(SportSelectionType).map(option => (
                                        <FormControlLabel
                                            control={<Radio />}
                                            key={option}
                                            label={option}
                                            value={option}
                                        />
                                    ))}
                                </RadioGroup>
                            </EditFormItem>
                            {type.value === SportSelectionType.Dates && [
                                <EditFormItem name="Start Date" required key="startDate">
                                    <DateTimePicker
                                        onChange={value => {
                                            startDate.onChange(value ? value.toDate() : null);
                                        }}
                                        sx={{ width: '100%' }}
                                        format="MMM D, YYYY hh:mm A"
                                        value={startDate.value ? dayjs(startDate.value) : null}
                                        slotProps={{
                                            textField: {
                                                onBlur: startDate.enableAutoValidationAndValidate,
                                                error: startDate.hasError,
                                                helperText: startDate.error,
                                            },
                                        }}
                                    />
                                </EditFormItem>,
                                <EditFormItem name="End Date" required key="endDate">
                                    <DateTimePicker
                                        onChange={value => {
                                            endDate.onChange(value ? value.toDate() : null);
                                        }}
                                        sx={{ width: '100%' }}
                                        format="MMM D, YYYY hh:mm A"
                                        value={endDate.value ? dayjs(endDate.value) : null}
                                        slotProps={{
                                            textField: {
                                                onBlur: endDate.enableAutoValidationAndValidate,
                                                error:
                                                    endDate.hasError ||
                                                    formState.error === INVALID_DATE_RANGE_ERROR,
                                                helperText:
                                                    formState.error === INVALID_DATE_RANGE_ERROR
                                                        ? 'End date cannot be before the start date.'
                                                        : endDate.error,
                                            },
                                        }}
                                    />
                                </EditFormItem>,
                            ]}
                            {type.value === SportSelectionType.Matches && (
                                <Box p={2} pt={3}>
                                    <SportSelectionMatches />
                                </Box>
                            )}
                        </Card>
                        {leaguesFormState.$.size > 1 && (
                            <Button
                                sx={{ mt: 2 }}
                                variant="text"
                                endIcon={<TrashIcon />}
                                color="error"
                                onClick={handleRemove}
                            >
                                Remove
                            </Button>
                        )}
                    </AccordionDetails>
                </Accordion>
            );
        },
    ),
);
